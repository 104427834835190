import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, Input, Menu, Button, Dropdown, Modal } from "antd";
import "../../App.less";
import api from "../../services/api";
import { Stars } from '../../components';
import moment from 'moment';
import {
    MoreOutlined, ExclamationCircleOutlined
} from "@ant-design/icons";

export default function ManutencaoComentario({ form, setLoad, record }) {

    const [comment, setComment] = useState([]);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        scrollToBottom()
    }, [comment]);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(() => {
        api.get(`Comentarios/GetComentarios?abaId=${record.aba_id}`).then((result) => {
            console.log('result.data :>> ', result.data);
            if (result.status === 200) {
                setComment(result.data);
            }
        }).catch((err) => {
            console.log('err :>> ', err);
        }).finally(() => setLoad(false));
    }, [])

    const onFinish = async values => {  
        setLoad(true);
        let data = {
            com_id: values.comId ? values.comId : 0,
            aba_id: record.aba_id,
            com_sequencia: values.comSequencia ? values.comSequencia : 0,
            com_tipo: 2,
            com_comentario: values.comComentario
        };
        api.post(`Comentarios/SaveComentario`, data).then((result) => {
            console.log('result :>> ', result);          
            if (result.status === 200) {
                if (values.comId > 0) {
                    let list = [...comment];
                    let index = list.findIndex(x => x.comId === values.comId);
                    if (index > -1) {
                        list[index] = result.data;
                        setComment(list);
                    }
                } else {
                    setComment(prev => ([...prev, result.data]));
                }
                form.resetFields();
            }
        }).catch((err) => {
            console.log('err :>> ', err);
        }).finally(() => setLoad(false));
    };

    function menuTable(value) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button onClick={() => editComentario(value)} type="text">Editar</Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="text" onClick={() => deleteComentario(value.comId)}>Delete</Button>
                </Menu.Item>
            </Menu>
        )
    }

    const editComentario = values => {
        console.log('values :>> ', values);
        form.setFieldsValue({ ...values });
    }

    const deleteComentario = value => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja realmente deletar este comentário?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                api.delete(`Comentarios/Delete?comId=${value}`).then((result) => {
                    if (result.status === 200) {
                        console.log(' deletado com sucesso !');
                        let list = [...comment];
                        let index = list.findIndex(x => x.comId === value)
                        if (index > -1) {
                            list.splice(index, 1);
                        }
                        setComment(list);
                    }
                }).catch((err) => {
                    console.log('err :>> ', err);
                });
            }
        });
    }

    return (
        <Form layout="vertical" name="manutencaoComentarios" form={form} initialValues={{ con_id: 0, com_sequencia: 0 }} onFinish={onFinish}>
            <Row justify="space-between" align="middle" gutter={[8, 8]}>
                <Col span={10}>
                    <b>{record.cli_nome}</b>
                </Col>
                <Col className="text-right" span={4}>
                    <Stars quantidade={record.aba_avaliacao} />
                    <span> {moment(record.aba_data_cadastro_avaliacao).format('DD/MM/YYYY HH:mm')}</span>
                </Col>
                <Col span={24}>
                    <div>
                        {record.aba_obsavaliacao}
                    </div>
                </Col>
            </Row>

            <div className="center-col">
                {comment.map((x, key) => (
                    <Row justify="space-between" align="middle" gutter={[8, 8]} className={x.comTipo === 1 ? '' : 'balloon'}>
                        <Col span={6}>
                            <b>Equipe Posto Primeiro</b>
                        </Col>
                        <Col className="text-right" span={4}>
                            <span> {moment(x.comDataCadastro).format('DD/MM/YYYY HH:mm')}</span>
                        </Col>
                        <Col span={24}>
                            <div key={key}>{x.comComentario}</div>
                        </Col>
                        {x.comTipo === 2 && <Col span={24} className="text-right">
                            <Dropdown overlay={menuTable(x)}>
                                <Button type="link" onClick={e => e.preventDefault()} icon={<MoreOutlined size="16" color="black" />} />
                            </Dropdown>
                        </Col>}
                    </Row>
                ))}
                <div ref={messagesEndRef} />
            </div>

            <Row align="middle" gutter={[8, 8]} className="positionBottom">
                <Form.Item name="comId" hidden />
                <Form.Item name="comSequencia" hidden />
                <Col span={24}>
                    <Form.Item label="Responder Comentário" name="comComentario" rules={[{ required: true, message: 'Está informação é obrigatória.' }]}>
                        <Input.TextArea rows="4" placeholder="Informe seu comentário aqui..." />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}