import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Layout } from "antd";
import { UserOutlined, SendOutlined, MessageOutlined, LineChartOutlined, StarOutlined, TrophyOutlined } from "@ant-design/icons";
import "./index.less";
// import api from '../../services/api';

export default function MenuPage(incollapsed) {

    const [collapsed, setCollapsed] = useState(false);
    const [openKeys, setOpenKeys] = useState([]);
    const rootKeys = ["sub1", "sub2", "sub3", "sub4", "sub5", "sub6", "sub7", "sub8", "sub9", "sub10"];
    
    // const [linkSuperset, setLinkSuperset] = useState("");
    // useEffect(() => {
    //     api.post(`SuperSet/GetURL`).then(res => {
    //         if (res.status === 200) {
    //             setLinkSuperset(res.data);
    //         }
    //     }, err => {
    //         console.log(err.response)
    //     }).finally();
    // }, [])

    useEffect(() => {
        if (!incollapsed) {
            setOpenKeys(openKeys);
        }
    }, [openKeys, incollapsed]);

    const onOpenChange = (items) => {
        const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
        if (rootKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(items);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : openKeys);
        }
    };

    return (
        <Layout.Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} className="menu-lateral">
            <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]} onOpenChange={onOpenChange} openKeys={openKeys}>
                {/* <Menu.Item>
                    <Link to="/">
                        <HomeOutlined />
                        <span>Home</span>
                    </Link>
                </Menu.Item> */}
                <Menu.Item>
                    <Link to="/clientes">
                        <UserOutlined />
                        <span>Clientes</span>
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    {/* <LineChartOutlined />
                    <span>
                        <a href={linkSuperset} target="blank">Análise</a>
                    </span> */}
                    <Link to="/dashboard">
                        <LineChartOutlined />
                        <span>Análise</span>
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/awards">
                        <TrophyOutlined />
                        <span>Prêmios</span>
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/sms">
                        <MessageOutlined />
                        <span>Mensagens Modelo</span>
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/sendSms">
                        <SendOutlined />
                        <span>Mensagens Enviadas</span>
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/avaliacoes">
                        <StarOutlined />
                        <span>Avaliações</span>
                    </Link>
                </Menu.Item>
            </Menu>
        </Layout.Sider>
    );
}