import React, { useEffect, useState } from "react";
import { Form, notification, Button, Drawer, Modal } from "antd";
import "../../App.less";
import api from "../../services/api";
import { CloseOutlined, SaveFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import ManutencaoMensagem from './manutencao';

export default function DrawerMensagem({ exibirDrawer, fecharDrawer, dataRegister }) {

    const [formMensagem] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        if (!!dataRegister.msmId > 0) {
            console.log('dataRegister :>> ', dataRegister);
            if (dataRegister.msmBoasVindas) {
                dataRegister.optionSend = 1;
            } else if (dataRegister.msmAniversario) {
                dataRegister.optionSend = 2;
            } else if (dataRegister.msmDiasSemAbastecimento > 0) {
                dataRegister.optionSend = 3;
            }
            formMensagem.setFieldsValue(dataRegister);
        } else {
            formMensagem.resetFields();
            formMensagem.setFieldsValue({ msmId: 0, msmAtivo: true, msmBoasVindas: false });
        }
    }, [dataRegister]);

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                //setRegistroManutencao({});
                formMensagem.resetFields();
                fecharDrawer();
            }
        });
    };

    const onFinish = async values => {
        debugger
        if (values.hasOwnProperty('optionSend')) {
            if (values.optionSend > 0) {
                let result = await verifyMessage(values.optionSend, values.msmDiasSemAbastecimento);
                console.log('result verify message :>> ', result);
                if (result) {
                    Modal.confirm({
                        title: 'Atenção!',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Você já possuí uma mensagem deste typo cadastrada, deseja altera-la? ',
                        okText: 'Sim',
                        cancelText: 'Não',
                        okType: 'danger',
                        centered: true,
                        onOk() {
                            values.msmId = result.msmId;
                            insert(values);
                        }
                    });
                }
                else {
                    insert(values);
                }
            } else {
                insert(values);
            }
        } else {
            insert(values);
        }
    };

    const insert = (values) => {
        debugger
        console.log('values :>> ', values);
        values.msmBoasVindas = values.optionSend === 1;
        values.msmAniversario = values.optionSend === 2;
        values.msmDescricao = values.msmDescricao ? values.msmDescricao : '';
        delete values.optionSend;

        api.post('MensagemMarketing/Save', values).then(res => {
            if (res.status === 200) {
                if (res.data?.statusCode)
                    notification.warning({ message: res.data.value.message, description: 'Verifique os dados informados e tente novamente.' });
                else {
                    notification.success({ message: 'Sucesso!', description: 'Mensagem salva com sucesso...' });
                    fecharDrawer();
                }
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setCarregando(false));
    }

    const verifyMessage = async (typeMessage, numDias = 0) => {
        return await api.get(`MensagemMarketing/verifyExistMessage?typeMessage=${typeMessage}&numDias=${numDias}`).then(res => {
            console.log({ res });
            if (res.status === 200) {
                if (res.data)
                    return res.data;
            }
            return null;
        }, err => {
            return null;
        });
    }

    return (
        <Drawer title="Cadastro de Mensagem"
            width="50%"
            visible={exibirDrawer}
            destroyOnClose={true}
            onClose={onClose}
            footer={
                <div className="right">
                    <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button" className="m-r-8">
                        Cancelar
                    </Button>
                    <Button onClick={() => formMensagem.submit()} loading={carregando} icon={<SaveFilled />} size="large" type="primary" htmlType="submit">
                        Salvar
                    </Button>
                </div>
            }>
            <div className="pages-col">
                <ManutencaoMensagem form={formMensagem} onFinish={onFinish} />
            </div>
        </Drawer>

    );
}