import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, Form, Input, Button, Table, Tag, Popover, Menu } from "antd";
import {
    QuestionCircleOutlined, DeleteFilled, PlusOutlined, PrinterFilled, EditFilled
} from "@ant-design/icons";

import "../../App.less";
import DrawerMensagem from './drawerMensagem';
import api from '../../services/api';

export default function Sms() {

    const [open, setOpen] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [openManutencao, setOpenManutencao] = useState(false);
    const [registroManutencao, setRegistroManutencao] = useState({});
    const [listaMensagemMarketing, setListaMensagemMarketing] = useState({});
    const [dataRegister, setDataRegister] = useState({});

    const [page, setPage] = useState(1);
    const [numPage, setNumPage] = useState(10);

    const [pesquisaMensagem, setPesquisaMensagem] = useState("");
    const [onInitIndex, setOnInitIndex] = useState(false);

    async function showDrawer(item) {
        if (item.msmId > 0) {
            setDataRegister(item);
        } else setDataRegister({ msmId: 0 });
        setOpenManutencao(true);
    };

    useEffect(() => carregaMensagem(), [pesquisaMensagem, numPage, page, onInitIndex, openManutencao]);

    function carregaMensagem() {
        if (pesquisaMensagem) setNumPage(1);

        setCarregando(true);
        api.get(`MensagemMarketing/GetAll?numRegister=${numPage}&numPage=${page}&filter=${pesquisaMensagem}`).then(res => {
            if (res.status === 200) {
                setListaMensagemMarketing(res.data);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setCarregando(false));
    }


    const menuTable = (
        <Menu className="text-right">
            <Menu.Item key="1">
                <Button type="link" icon={<DeleteFilled />}> Remover </Button>
            </Menu.Item>
            <Menu.Item key="2">
                <Button type="link" icon={<PrinterFilled />}> Editar </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="p-10">
            <Row align="middle" gutter={[8, 8]}>
                <Col span={20}>
                    <span>
                        <h2 className="title-dashboard">Mensagens</h2>
                    </span>
                </Col>
                <Col span={4}>
                    <Button type="primary" className="right" icon={<PlusOutlined />} size="middle" onClick={() => showDrawer({})}>
                        NOVO
                    </Button>
                </Col>
            </Row>
            <div>
                <Form layout="vertical">
                    <Row align="middle" gutter={[8, 8]}>
                        <Col sm={24}>
                            <Form.Item>
                                <div>
                                    <label> Pesquisar por </label>
                                    <Tooltip title="Mensagem">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>
                                <Input.Search allowClear="true" placeholder="Mensagem..." value={pesquisaMensagem}
                                    onChange={e => setPesquisaMensagem(e.target.value)}
                                    enterButton />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <div className="tabela">
                    <Table loading={carregando} columns={
                        [
                            {
                                title: 'Código',
                                width: 100,
                                align: 'center',
                                fixed: 'left',
                                render: ({ msmId, msmAtivo }) => (
                                    <Popover content={msmAtivo ? 'Ativo' : 'Inativo'} placement="right">
                                        <Tag color={msmAtivo ? 'green' : 'default'}>
                                            <b>#{msmId}</b>
                                        </Tag>
                                    </Popover>
                                ),
                            },
                            {
                                title: 'Título',
                                fixed: 'left',
                                render: ({ msmTitulo }) => (
                                    <div>
                                        <b>{msmTitulo}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Mensagem',
                                fixed: 'left',
                                render: ({ msmDescricao }) => (
                                    <div>
                                        <b>{msmDescricao}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Boas vindas?',
                                fixed: 'left',
                                align: 'center',
                                render: ({ msmBoasVindas }) => (
                                    <div>
                                        <b>{msmBoasVindas ? 'Sim' : ''}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Aniversário?',
                                fixed: 'left',
                                align: 'center',
                                render: ({ msmAniversario }) => (
                                    <div>
                                        <b>{msmAniversario ? 'Sim' : ''}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Dias Sem Abastecimento?',
                                fixed: 'left',
                                align: 'center',
                                render: ({ msmDiasSemAbastecimento }) => (
                                    <div>
                                        <b>{msmDiasSemAbastecimento > 0 ? msmDiasSemAbastecimento + ' Dias' : ''}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Operações',
                                dataIndex: 'operation',
                                align: 'center',
                                fixed: 'right',                        
                                render: (text, record) => (
                                    <div>
                                        <Button onClick={() => showDrawer(record)} icon={<EditFilled />} />
                                    </div>
                                ),
                            },
                        ]}
                        pagination={{
                            current: page,
                            pageSize: numPage,
                            total: listaMensagemMarketing.total,
                            onChange: (pg) => setPage(pg),
                            onShowSizeChange: (current, page) => setNumPage(page)
                        }}
                        dataSource={listaMensagemMarketing.data}
                    />
                </div>
            </div>
            <DrawerMensagem dataRegister={dataRegister} dadosManutencao={registroManutencao} setRegistroManutencao={setRegistroManutencao} exibirDrawer={openManutencao} fecharDrawer={() => setOpenManutencao(false)} onInitIndex={setOnInitIndex} />
        </div>
    );

}