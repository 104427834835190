import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "antd";
import "../../App.less";
import { Summernote } from '../../components';

export default function TabPush({form, required}) {

    const [message, setMessage] = useState('');

    useEffect(()=>{
        if(form.getFieldValue().msmMensagemCustom){
            setMessage(form.getFieldValue().msmMensagemCustom);
        }
    },[])

    useEffect(() => {
        form.setFieldsValue({ msmMensagemCustom: message });
    }, [message])

    return (
        <div className="pages-col">
            <Row align="middle" gutter={[8, 8]}>
                <Col span={24}>
                    <Form.Item label="Mensagem" name="msmMensagemCustom">
                        <Summernote setMessage={setMessage} message={message} height={200} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}