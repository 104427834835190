
export default function formatNumber(value, list = false, prefix = true, qtd = false) {
    if (!!value) {       
        let negativo = false;
        if (list) {
            value = value.toString().replaceAll(',', '.');
            value = parseFloat(value).toFixed(2);
        }
        if (value < 0) {
            negativo = true;
        }
        value = value.toString().replace(/\D/g, "");
        value = value.toString().replace(/(\d)(\d{8})$/, "$1.$2");
        value = value.toString().replace(/(\d)(\d{5})$/, "$1.$2");
        if (qtd)
            value = value.toString().replace(/(\d)(\d)$/, "");
        else
            value = value.toString().replace(/(\d)(\d{2})$/, "$1,$2");

        if (negativo) {
            value = '-' + value;
        }
        if (prefix) {
            return `R$ ${value}`;
        } else {
            return value;
        }
    } else {
        //console.log('problema no formatNumber, value não é valido');
        if (prefix) {
            return 'R$ 0,00';
        } else {
            return '0,00';
        }

    }
}