import React, { useRef, useState, useEffect } from "react";
import { Tabs, Row, Col, Form, Checkbox, Radio, Select, Input, notification } from "antd";
import "../../App.less";
import TabSms from "./tabSms";
import TabPush from "./tabPush";
import api from '../../services/api';

export default function ManutencaoMensagem({ form, onFinish, moreOptions = true }) {

    const [tabOption, setTabOption] = useState('1');
    const focusInput = useRef();
    const [listDias, setListDias] = useState([{ dias: 10 }, { dias: 15 }, { dias: 30 }])

    useEffect(() => {
        api.get(`MensagemMarketing/GetDiasMensagem`).then((result) => {
            console.log('result :>> ', result);
            if (result.status === 200) {
                let data = result.data;
                let list = [...listDias];
                let currentList = [];
                for (const item of list) {
                    let index = data.findIndex(x => x.dias === item.dias);
                    if (index === -1) {
                        currentList.push(item);
                    }
                }
                setListDias(currentList);
            }
        }).catch((err) => {
            console.log('err :>> ', err);
        });
    }, [])

    const onFinishFailed = (values) => {
        console.log('values :>> ', values);
        if (values.errorFields.length > 0) {
            notification.warning({ message: "Atenção!", description: 'Campos obrigatórios não foram informados.' });
        }
    }

    return (
        <Form layout="vertical" form={form} name="manutencaoMensagem" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Row align="middle" gutter={[8, 8]}>
                <Form.Item name="msmId" hidden />
                <Form.Item name="msmMensagemCustom" hidden />
                <Col span={24}>
                    <Form.Item label="Título da mensagem:" name="msmTitulo" rules={[{ required: true, message: 'Informe o Título' }]}>
                        <Input placeholder="Informe o Título" />
                    </Form.Item>
                </Col>
            </Row>
            <Tabs defaultActivekey="1" centered onChange={(key) => setTabOption(key)}>
                <Tabs.TabPane tab="MENSAGEM" key="1">
                    <TabSms />
                </Tabs.TabPane>
                <Tabs.TabPane tab="MENSAGEM PERSONALIZADA" key="2">
                    <TabPush form={form} required={tabOption === '2'} />
                </Tabs.TabPane>
            </Tabs>
            <Row align="middle" gutter={[8, 8]}>
                <Col span={24}>
                    <Form.Item name="msmAtivo" valuePropName="checked">
                        <Checkbox>Mensagem Ativa?</Checkbox>
                    </Form.Item>
                </Col>
                {moreOptions && <Col span={20}>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.optionSend !== currentValues.optionSend}>
                        {({ getFieldValue }) => {
                            if (focusInput.current) focusInput.current.focus();
                            return <>
                                <Form.Item name="optionSend">
                                    <Radio.Group>
                                        <Radio className="inLine" value={1}>Mensagem de Boas Vindas</Radio>
                                        <Radio className="inLine" value={2}>Mensagem de aniversário</Radio>
                                        {listDias.length > 0 && <Radio className="inLine" value={3}>Clientes que estão sem efetuar abastecimento</Radio>}
                                    </Radio.Group>
                                </Form.Item>
                                {getFieldValue('optionSend') === 3 &&
                                    <Col Col span={12} className="n-d-s">
                                        <Form.Item label="Selecione o número de dias:" name="msmDiasSemAbastecimento" rules={[{ required: (getFieldValue('optionSend') === 3), message: 'O número de dias deve ser informado.' }]}>
                                            <Select>
                                                {listDias.map((x, i) => (
                                                    <Select.Option key={i} value={x.dias}>{x.dias} Dias</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                            </>
                        }}
                    </Form.Item>
                </Col>}
            </Row>
        </Form>
    );
}