import React from "react";
import { ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { isAuthenticated } from './services/auth';

import "./App.less";

import { Home, Login } from "./pages";

export default function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

  return (

    <ConfigProvider locale={ptBR} componentSize="small">
      <Router>
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/login/changepass/:hash/:email/:typesystem" component={Login} />                                    
          <PrivateRoute path="/home" component={Home} />
        </Switch>
      </Router>
    </ConfigProvider>

  );

}