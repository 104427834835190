import React, { useState } from "react";
import { Row, Col, Upload, Modal, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { urlApi } from '../../services/auth';

export default function Index({ proId, onSave, onRemove }) {

    const [previewImage, setPreviewImage] = useState('');
    const [previewVisible, setPreviewVisible] = useState(false);

    const [fileList, setFileList] = useState([
        {
            uid: proId,
            name: 'image.png',
            status: 'done',
            url: `${urlApi}Imagens/GetImgProduto?proId=${proId}&sequencia=2`,
        },
    ]);

    const onChange = ({ fileList: newFileList }) => {

        if (newFileList.length > 0) {
            const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('A imagem deve ter um tamanho menor que 2MB!');
            }else{
                setFileList(newFileList);
                onSave(newFileList)
            }
        } else
            setFileList(newFileList);


    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        setPreviewImage(src);
        setPreviewVisible(true);
    };

    return (
        <div>
            <div>       
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    accept="image/jpeg,image/png,image/jpg"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    onRemove={onRemove}
                >
                    {fileList.length < 1 && '+ Selecionar'}
                </Upload>
            </div>
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img alt="Imagem Produto" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    );
}