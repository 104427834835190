import React, { useState } from "react";
import { Modal, Button } from "antd";
import Summernote from "../Summernote";
import { CloseOutlined, SendOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

export default function Index({ display, onCloseModal, onSave, message }) {
    const [messageCustom, setMessageCustom] = useState("");

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar? ',
            icon: <ExclamationCircleOutlined />,
            content: 'Tem certeza que deseja cancelar e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                setMessageCustom("");
                onCloseModal();
            }
        });
    }

    return (
        <Modal
            centered
            destroyOnClose
            width={1000}
            title="Mensagem Personalizada"
            visible={display}
            onCancel={onClose}
            footer={
                <>
                    <Button  size="middle" onClick={onClose} icon={<CloseOutlined />} htmlType="button" className="m-r-8">
                        Cancelar
                    </Button>
                    <Button  size="middle" icon={<SendOutlined />} type="primary" htmlType="submit" onClick={() => onSave(messageCustom)}>
                        Salvar
                    </Button>
                </>
            }>
            <Summernote message={message} setMessage={setMessageCustom} />
        </Modal>

    );
}