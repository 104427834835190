import React, { useState, useEffect } from 'react';
import { Form, Modal, Row, Col, DatePicker, Input, Spin, Button } from "antd";
import {
    FilePdfOutlined
} from "@ant-design/icons";
import "../../App.less";
import moment from 'moment';
import { MaskFormat } from '../../ValueObjects'
import api from '../../services/api';
import { saveAs } from 'file-saver';


export default function ModalSorteio({ isModalVisible, setVisible, dadosForm, sorteado, locais, loading }) {

    const [form] = Form.useForm();
    const [width, setWidth] = useState(1000);
    const [height, setHeight] = useState(400);
    const [dadosLoad, setDadosLoad] = useState({});
    const [loadPdf, setloadPdf] = useState(false);


    useEffect(() => {
        // let local = dadosForm.locais;
        // let locDescricao = null;
        // if (local)
        //     locDescricao = locais.find(x => x.locId === local).locDescricao;

        // dadosForm.local = locDescricao;
        form.setFieldsValue(dadosForm);
        setDadosLoad(dadosForm);
    }, [isModalVisible]);


    const gerenatePdf = () => {
        debugger
        setloadPdf(true);

        let values = { ...dadosForm };
        let isRegister = '';

        if (values.isRegisterApp === 0)
            isRegister = 1;
        else if (values.isRegisterApp === 1)
            isRegister = 0;
        else
            isRegister = '';

        if (!!values.periodoCadastral)
            values.periodoCadastral = values.periodoCadastral.map((x) => (x.format('MM/DD/YYYY')));
        if (!!values.periodoNascimento)
            values.periodoNascimento = values.periodoNascimento.map((x) => (x.format('MM/DD/YYYY')));

        if (!!values.dataAbastecimento)
            values.dataAbastecimento = values.dataAbastecimento.map((x) => (x.format('MM/DD/YYYY')));

        let data = {
     
            periodoCadastral: values.periodoCadastral ? values.periodoCadastral.join() : '',
            periodoNascimento: values.periodoNascimento ? values.periodoNascimento.join() : '',
            local: values.locais? values.locais.join(): null,
            isRegisterApp: isRegister,
            melhorClient: values.bestCustomers ? 1 : 0,
            cidade: values.cidade,
            valor: values.valor ? values.valor : 0,
            dataAbastecimento: values.dataAbastecimento ? values.dataAbastecimento.join() : '',
        };

        api.post(`Clientes/GeneratePdf`, data).then(res => {
            if (res.status === 200) {
                const byteArray = new Uint8Array(atob(res.data.toString()).split('').map(char => char.charCodeAt(0)));
                saveFile(byteArray, "Clientes");
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setloadPdf(false));
    }

    function saveFile(byteArray, fileName) {
        var blob = new Blob([byteArray], { type: 'application/pdf' });
        saveAs(blob, fileName);
    };

    return (
        <Modal
            width={width + 100}
            height={height + 100}
            title="Cliente Sorteado"
            visible={isModalVisible}
            cancelText="Fechar"
            onCancel={() => setVisible(false)}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: sorteado.cliNome ? '' : 'none' } }}
            footer={[
                <Button
                    onClick={gerenatePdf}
                    size="middle"
                    type="primary"
                    loading={loadPdf}
                    icon={<FilePdfOutlined />}> Baixar relação de clientes PDF </Button>
            ]}
            className="t-59">
            <Form form={form}>
                <Row align="middle" justify="space-around" gutter={[0, 8]}>
                    {dadosLoad.periodoCadastral &&
                        <Col span={5}>
                            <Form.Item label="Clientes cadastrados no período " name="periodoCadastral">
                                <DatePicker.RangePicker disabled={true} format="DD/MM/YYYY" />
                            </Form.Item>
                        </Col>
                    }
                    {dadosLoad.periodoNascimento &&
                        <Col span={5}>
                            <Form.Item label="Clientes nascidos no período" name="periodoNascimento">
                                <DatePicker.RangePicker disabled={true} format="DD/MM/YYYY" />
                            </Form.Item>
                        </Col>
                    }
                    {dadosLoad.local &&
                        <Col span={10}>
                            <Form.Item label="Local" name="local">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Row align="middle" justify="start" gutter={[0, 8]}>
                    <Col span={13}>
                        {dadosLoad.isRegisterApp === 0 && <span>Clientes cadastrados via aplicativo</span>}
                        {dadosLoad.isRegisterApp === 1 && <span>Clientes cadastrados via sistema</span>}
                        {(dadosLoad.isRegisterApp === undefined || dadosLoad.isRegisterApp === 2) && <span>Todos clientes</span>}
                    </Col>
                    <Col span={11}></Col>
                </Row>
                {!loading && <span>
                    {sorteado.cliNome && <Row align="middle" justify="start" gutter={[0, 8]}>
                        <Col span={4}>
                            <img src={require('../../assets/trophy.png')} alt="Trophy" width="200" />
                        </Col>
                        <Col span={20} className="p-l-20">
                            <div><h1>{sorteado.cliNome}</h1></div>
                            <div>{sorteado.cliCodigo ? 'N° ' + sorteado.cliCodigo : ''}</div>
                            <div>{sorteado.cliEmail}</div>
                            {sorteado.cliCelular != undefined &&
                                <div>{sorteado.cliCelular != null ? MaskFormat(sorteado.cliCelular, "(00) 00000-0000") : "Telefone não informado."}</div>
                            }
                            <div>{sorteado.cliCidade} - {sorteado.cliUf}</div>
                            {sorteado.cliCpfCnpj != undefined && sorteado.cliCpfCnpj.length > 11 &&
                                <div>{MaskFormat(sorteado.cliCpfCnpj, "00.000.000/0000-00")}</div>
                            }
                            {sorteado.cliCpfCnpj != undefined && sorteado.cliCpfCnpj.length == 11 &&
                                <div>{MaskFormat(sorteado.cliCpfCnpj, "000.000.000-00")}</div>
                            }
                            <div>Data de Nascimento: {sorteado.cliDataNascimento != null ? moment(sorteado.cliDataNascimento).format('DD/MM/YYYY') : ''}</div>
                            <div>Utiliza o App desde: {sorteado.cliDataCadastroApp != null ? moment(sorteado.cliDataCadastroApp).format('DD/MM/YYYY') : ''}</div>
                        </Col>
                    </Row>}
                    {!sorteado.cliNome && <Row align="bottom" justify="space-around" gutter={[0, 8]}>
                        <Col className="p-l-20">
                            <div><h1>Nenhum cliente encontrado a partir dos filtros aplicados!</h1></div>
                        </Col>
                    </Row>}
                </span>}
                {loading && <Row align="bottom" justify="space-around" gutter={[0, 8]}>
                    <Col className="p-l-20">
                        <Spin size="large" />
                    </Col>
                </Row>}

            </Form>

        </Modal>
    )

}