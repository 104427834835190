import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Typography, Form, Input, Button, notification } from 'antd';
import { UnlockFilled, MailOutlined, SendOutlined } from '@ant-design/icons';

import { EsqueciSenha, SlideLogin, RecuperarSenha } from "./pages";
import api from '../../services/api';
import { login, setUser, EMPRESA_KEY } from '../../services/auth';
import { useParams } from "react-router-dom";

import "./login.less";
//import logo from '../../assets/logoGestor.png';
import { ModulosPage } from "../../components";

const FORMULARIO = {
    LOGIN: 1,
    SELECIONA_EMPRESA: 2,
    ESQUECI_SENHA: 3,
    ALTERACAO_SENHA: 4,
}

const { Content } = Layout;

export default function Login() {

    const { hash, email, typesystem } = useParams();
    const { Text } = Typography;
    const [formularioExibir, setFormularioExibir] = useState(FORMULARIO.LOGIN);
    const [carregando, setCarregando] = useState(false);
    const [hashAcess, setHashAcess] = useState("");
    const [emailAcess, setEmailAcess] = useState("");
    const [logo, setLogo] = useState(null);

    useEffect(() => {
        if (hash) {
            setFormularioExibir(FORMULARIO.ALTERACAO_SENHA);
            setHashAcess(hash);
            setEmailAcess(email);
            if (typesystem !== "" && typesystem !== undefined) {
                let systemLogo = atob(typesystem);
                setLogo(require("../../assets/" + systemLogo + ".png"));
            } else {
                setLogo(require("../../assets/logoGestor.png"));
            }
        } else {
            setLogo(require("../../assets/logoGestor.png"));
        }
    })

    const onFinish = values => {
        setCarregando(true);
        //values.usu_spassword = btoa(values.usu_spassword);
        values.emp_key = EMPRESA_KEY;
        delete values.remember;
        console.log({ values });

        api.post('Usuario/AuthAdmin', values).then(res => {
            console.log({ res });
            if (res.status === 200) {
                if (res.data?.statusCode)
                    notification.warning({ message: res.data.value.message, description: 'Verifique os dados informados e tente novamente.' });
                else {
                    const token = res.data.token.access_token;
                    login(token);
                    setUser(JSON.stringify(res.data));
                    window.location = "/Home";
                }
            }
        }, err => {
            console.log(err.response);
            notification.error({ message: 'Falha ao efetuar o login', description: 'Verifique os dados informados e tente novamente.' });
        }).finally(() => setCarregando(false));
    };

    const onFinishFailed = errorInfo => {
        console.log('Erro ao efetuar o login:', errorInfo);
    };


    return (
        <Layout className="vh100">
            <Content>
                <Row type="flex" justify="center" className="vh100">
                    <Col xs={0} sm={0} md={13} xl={13} className="vh100 fixed-left">
                        <SlideLogin />
                    </Col>
                    <Col xs={24} sm={24} md={11} xl={11} className="vh100 bg-log fixed-right">
                        <Row type="flex" align="middle" justify="center" className="vh100">
                            <Col span={18}>
                                <div className="text-center">
                                    <img src={logo} width="200" alt="Gestor Web" />
                                </div>

                                {formularioExibir === FORMULARIO.LOGIN &&
                                    <div className="m-t-15">
                                        <Text className="f-14">
                                            Identifique abaixo suas credenciais para realizar o acesso.
                                        </Text>
                                        <Form name="login" initialValues={{ remember: true }} size="middle" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                                            <Row align="middle" gutter={[0, 16]}>
                                                <Col span={24}>
                                                    <Form.Item name="usu_semail" rules={[{ required: true, message: 'Informe seu e-mail para realizar o login.' }]}>
                                                        <Input placeholder="Informe seu e-mail" prefix={<MailOutlined />} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="usu_spassword" rules={[{ required: true, message: 'Informe sua senha de acesso.' }]}>
                                                        <Input.Password placeholder="Informe sua senha" prefix={<UnlockFilled />} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <div className="flexContent flexDirectionRowReverse">
                                                        <Form.Item>
                                                            <Button type="primary" htmlType="submit" loading={carregando} >
                                                                Acessar <SendOutlined />
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <div className="flexContent justifyContentCenter">
                                                        <Form.Item>
                                                            <Button type="primary" htmlType="button" onClick={() => setFormularioExibir(FORMULARIO.ESQUECI_SENHA)} size="small">
                                                                Esqueci minha senha <UnlockFilled />
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                }
                                {formularioExibir === FORMULARIO.ESQUECI_SENHA && <EsqueciSenha onAcessar={() => setFormularioExibir(FORMULARIO.LOGIN)} />}
                                {formularioExibir === FORMULARIO.ALTERACAO_SENHA && hashAcess && <RecuperarSenha hash={hashAcess} typeSystem={typesystem} email={emailAcess} onAcessar={() => setFormularioExibir(FORMULARIO.LOGIN)} />}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row type="flex" justify="center" className="vh100 hide">
                    <Col span={16} className="vh100">
                        <ModulosPage />
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}