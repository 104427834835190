import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Tag, Table, Input } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CheckOutlined } from "@ant-design/icons";
import { MaskFormat } from '../../ValueObjects';
import api from '../../services/api';

export default function ListClientes({ listSelected, setListSelected, filter }) {

    const [loadingClientes, setLoadingClientes] = useState("");
    const [clientes, setClientes] = useState({});
    const [pesquisaCliente, setPesquisaCliente] = useState("");
    const [numPage, setNumPage] = useState(1);
    const [numRegister, setNumRegister] = useState(8);

    useEffect(() => loadData(), [numRegister, numPage, pesquisaCliente]);
    function loadData() {
        let data = {};
        data = { ...filter };
        data.numRegister = numRegister;
        data.numPage = numPage;
        data.reverse = true;
        data.filter = pesquisaCliente;

        setLoadingClientes(true);
        api.post(`Clientes/GetClientesByFilters`, data).then(res => {
            if (res.status === 200) {
                res.data.data = res.data.data.map((x) => ({ add: findItem(x.cliId), ...x }));
                setClientes(res.data);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setLoadingClientes(false));
    }

    const findItem = (cliId) => {
        console.log("Dados filtrados: ", filter);
        if (listSelected && listSelected.length > 0) {
            let index = listSelected.findIndex(x => x === cliId);
            if (index > -1) {
                if (Object.keys(filter).findIndex(x => x === "listClientsRemove") === -1) return false;
                return filter.listClientsRemove.findIndex(x => x === cliId) > -1 ? false : true;
            }
        }
        return false;
    }

    const changeClient = ({ cliId, add }) => {
        let option = !add;

        if (option) {
            if (listSelected.findIndex(x => x === cliId) === -1) {
                setListSelected([...listSelected, cliId]);
            }
        } else {
            if (listSelected.findIndex(x => x === cliId) > -1) {
                let dataList = [...listSelected];
                dataList.splice(dataList.findIndex(x => x.cliId === cliId), 1);
                setListSelected(dataList);
            }
        }

        let index = clientes.data.findIndex(x => x.cliId === cliId);
        if (index > -1) {
            let itens = { ...clientes };
            itens.data[index].add = option;
            setClientes(itens);
        }
    };


    return (
        <Row align="middle" gutter={[0, 8]}>
            <Col span={24}>
                <Typography.Text>
                    Incluir clientes a lista:
                    </Typography.Text>
            </Col>
            <Col span={24}>
                <Input.Search allowClear placeholder="Pesquise por código, nome, email, CPF... "
                    value={pesquisaCliente} onChange={e => setPesquisaCliente(e.target.value)}
                    enterButton />

                <div className="tabela minheight-table" >
                    <Table loading={loadingClientes}
                        locale={{
                            emptyText: (
                                <Row>
                                    <Col className="textEmpty" span={24}>
                                        <ExclamationCircleOutlined /> Nenhum cliente encontrado...
                                           </Col>
                                </Row>)
                        }}
                        columns={
                            [
                                {
                                    title: 'Código',
                                    width: 105,
                                    align: 'center',
                                    fixed: 'left',
                                    render: ({ cliId, cliCodigo }) => (
                                        <Tag color={'processing'}>
                                            <b>#{cliCodigo === 0 ? cliId : cliCodigo}</b>
                                        </Tag>
                                    ),
                                },
                                {
                                    title: 'Cliente',
                                    fixed: 'left',
                                    render: ({ cliNome }) => (
                                        <div>
                                            <b>{cliNome}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Email',
                                    fixed: 'left',
                                    render: ({ cliEmail }) => (
                                        <div>
                                            <span>{cliEmail}</span>
                                        </div>
                                    ),
                                }, {
                                    title: 'Telefone',
                                    fixed: 'left',
                                    render: ({ cliCelular }) => (
                                        <div>
                                            <span>{ cliCelular != null?MaskFormat(cliCelular, "(00) 00000-0000"):"Telefone não informado."}</span>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Opções',
                                    dataIndex: 'operation',
                                    render: (text, record) =>
                                        record.add ? (
                                            <Button onClick={() => { changeClient(record) }} icon={<CheckOutlined />} size="small" type="primary" >
                                                Adicionado
                                            </Button>)
                                            : (
                                                <Button onClick={() => { changeClient(record) }} icon={<PlusOutlined />} size="small" type="dashed" >
                                                    Adicionar
                                                </Button>),
                                }
                            ]}
                        pagination={{
                            current: numPage,
                            pageSize: numRegister,
                            total: clientes.total,
                            onChange: (pg) => setNumPage(pg),
                            onShowSizeChange: (current, page) => setNumRegister(page)
                        }}
                        dataSource={clientes.data}
                    />
                </div>
            </Col>
        </Row>
    );
}