import React, { useEffect, useState } from "react";
import { Form, notification, Button, Drawer, Modal } from "antd";
import "../../App.less";
import api from "../../services/api";
import { CloseOutlined, SendOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import ManutencaoComentario from './manuteucaoComentario'

export default function DrawerComentario({ show, onCloseDrawer, record }) {

    const [form] = Form.useForm();
    const [load, setLoad] = useState(false);


    const onClose = () => {
        onCloseDrawer();
        

    };
    return (
        <Drawer title="Comentários"
            width="50%"
            visible={show}
            destroyOnClose={true}
            onClose={onClose}
            footer={
                <div className="right">
                    <Button onClick={() => form.submit()}
                        loading={load}
                        icon={<SendOutlined />} size="large" type="primary" htmlType="submit">
                        Enviar
                    </Button>
                </div>
            }>
            <ManutencaoComentario setLoad={setLoad} form={form} record={record} />
        </Drawer>

    );
}