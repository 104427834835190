import React, { useState, useEffect } from 'react';
import { Form, Row, Button, Input, Select, Col, Table, Tag, Drawer, Modal, notification, Card, Popconfirm, Switch, message, Collapse } from 'antd';
import {
    CloseOutlined, UsergroupAddOutlined, ExclamationCircleOutlined,
    SendOutlined, UserAddOutlined, UserOutlined, DeleteOutlined, ClearOutlined,
    LinkOutlined, PlusOutlined
} from "@ant-design/icons";
import "../../App.less";
import api from '../../services/api';
import FilterClients from './filterClients';
import ModalAddCliente from './modalAddCliente';
import ModalSummernote from '../../components/modals/modalMensagemPersonalizada';
import { MaskFormat } from '../../ValueObjects';
import moment from 'moment';

//summernote
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";


export default function ManutencaoMensagem({ dataRegister, exibirDrawer, fecharDrawer }) {

    const [formSendSMS] = Form.useForm();

    const [mensagem, setMensagem] = useState("");
    const [loading, setLoading] = useState(false);
    const [OpenFilter, setOpenFilter] = useState(false);
    const [loadMessage, setLoadMessage] = useState(false);
    const [verifyClientes, setVerifyClientes] = useState(false);
    const [listaMensagemMarketing, setListaMensagemMarketing] = useState([]);

    const [filterClientes, setFilterClientes] = useState({});
    const [loadingClientes, setLoadingClientes] = useState(false);
    const [listClientes, setListClientes] = useState({});
    const [filterMensagem, setFilterMensagem] = useState("");
    const [totalRegister, setTotalRegister] = useState(0);
    const [saldoSMS, setSaldoSMS] = useState(0);

    const [pesquisaCliente, setPesquisaCliente] = useState("");
    const [numPage, setNumPage] = useState(1);
    const [numRegister, setNumRegister] = useState(10);
    const [visibleSelectClient, setVisibleSelectClient] = useState(false);
    const [checkedPush, setCheckedPush] = useState(false);
    const [openLink, setOpenLink] = useState(false);
    const [maxLength, setMaxLength] = useState(0);
    const [maxLengthTextArea, setMaxLengthTextArea] = useState(180);
    const [lengthMessageInformed, setLengthMessageInformed] = useState(0);

    const [modalSummernote, setModalSummernote] = useState(false);

    const [messageCustom, setMessageCustom] = useState(null);


    useEffect(() => {
        setSaldoSMS(dataRegister.saldo);

        if (dataRegister.ctmId > 0) {
            setTotalRegister(0);
            formSendSMS.setFieldsValue(dataRegister);
            setMensagem(dataRegister.msmDescricao);
            setMessageCustom(dataRegister.msmMensagemCustom);
            setOpenLink(!!dataRegister.msmLink);
            let data = {};
            data.listClientsAdd = dataRegister.clientes.data.map((x) => { return x.cliId });
            setFilterClientes(data);
        } else {
            setNumPage(1);
            setNumRegister(10);
            setTotalRegister(0);
            setMessageCustom(null);
            setListClientes([]);
            setFilterClientes({});
            formSendSMS.resetFields();
            formSendSMS.setFieldsValue({ ctmId: 0 });            
        }
    }, [dataRegister]);

    useEffect(() => loadData(), [filterMensagem]);
    function loadData() {
        setLoadMessage(true);
        api.get(`MensagemMarketing/GetAll?numRegister=5000&numPage=1&filter=${filterMensagem}&active=1`).then(res => {
            if (res.status === 200) {
                console.log('res.data.data :>> ', res.data.data);
                setListaMensagemMarketing(res.data.data);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setLoadMessage(false));
    }

    useEffect(() => {
        setListClientes({});

        if (Object.keys(filterClientes).length === 0)
            return;

        filterClientes.numRegister = numRegister;
        filterClientes.numPage = numPage;
        filterClientes.filter = pesquisaCliente;

        setLoadingClientes(true);
        api.post(`Clientes/GetClientesByFilters`, filterClientes).then(res => {
            if (res.status === 200) {
                setListClientes(res.data);
                if (!pesquisaCliente)
                    setTotalRegister(res.data.total);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setLoadingClientes(false));

    }, [filterClientes, numRegister, numPage, pesquisaCliente]);

    const searchClient = (value) => {
        setPesquisaCliente(value);
        setNumPage(1);
    }

    const renderItem = ({ cliNome, cliId }) => {
        return {
            label: renderTitle(cliNome),
            options: [{
                id: cliId,
                value: cliNome,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {cliNome}
                        <span>
                            <UserOutlined />
                        </span>
                    </div>
                ),
            }]
        }
    };

    const renderTitle = (title) => {
        return (
            <span>
                {title}
            </span>
        );
    };
    const onFinish = values => {

        let msg = formSendSMS.getFieldValue('msmDescricao');

        if (values.somentePush === undefined)
            values.somentePush = false;

        if (!values.somentePush) {
            if (totalRegister > saldoSMS) {
                Modal.warning({
                    title: 'Atenção',
                    content: 'Saldo insuficiente para SMS, por favor verifique seu saldo contratado!',
                    okType: 'danger',
                    centered: true,
                });
                return;
            } else if (msg > 180) {

                Modal.warning({
                    title: 'Atenção',
                    content: 'A mensagem informada é maior do que 180 caracteres, por favor, verifique-a pois este é o limite para um SMS!',
                    okType: 'danger',
                    centered: true,
                });
                return;
            }
        }

        if (listClientes.data === undefined || listClientes.data.length === 0) {
            setVerifyClientes(true);
            Modal.warning({
                title: 'Atenção',
                content: 'Selecione ao menos um cliente deve ser selecionado!',
                okType: 'danger',
                centered: true,
            });
            return;
        }

        if (formSendSMS.getFieldValue('msmLink')) {
            if (msg.includes('@Link') === false) {
                Modal.warning({
                    title: 'Atenção',
                    content: 'Você informou o link mas não adicionou "@Link" na mensagem, por favor revise-a!',
                    okType: 'danger',
                    centered: true,
                });
                return;
            }
        }

        setVerifyClientes(false);
        values.filterClientes = filterClientes;
        setLoading(true);
        api.post('/MensagensClientes/save', values).then(res => {
            if (res.status === 200) {
                notification.success({ message: 'Operação concluída sucesso!', description: "Mensagem enviada com sucesso!" });
                fecharDrawer();
            }
        }, err => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
        }).finally(() => {
            setLoading(false);
        });
    };

    const onChangeMessage = values => {
        let message = listaMensagemMarketing.find(x => x.msmId === values);
        console.log('message :>> ', message);

        formSendSMS.setFieldsValue({ msmDescricao: message.msmDescricao });
        formSendSMS.setFieldsValue({ msmTitulo: message.msmTitulo });
        debugger
        if (message.msmMensagemCustom) {
            formSendSMS.setFieldsValue({ msmMensagemCustom: message.msmMensagemCustom });
            setMessageCustom(message.msmMensagemCustom);
            formSendSMS.setFieldsValue({ somentePush: true });
            setCheckedPush(true);
        } else {
            formSendSMS.setFieldsValue({ msmMensagemCustom: '' });
            setMessageCustom(null);
            formSendSMS.setFieldsValue({ somentePush: false });
            setCheckedPush(false);
        }
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar? ',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    const onDeleteClient = ({ cliId }) => {
        if (Object.keys(filterClientes).findIndex(x => x === "listClientsRemove") === -1)
            filterClientes.listClientsRemove = [];

        let obj = { ...filterClientes };

        if (obj.listClientsRemove.findIndex(x => x === cliId) === -1) {
            let listrm = { ...obj.listClientsRemove };
            if (listrm.length > 0)
                obj.listClientsRemove([...obj.listClientsRemove, cliId]);
            else
                obj.listClientsRemove.push(cliId);
        }

        if (Object.keys(filterClientes).findIndex(x => x === "listClientsAdd") > -1) {
            let index = obj.listClientsAdd.findIndex(x => x === cliId);
            if (index > -1) {
                obj.listClientsAdd.splice(index, 1);
            }
        }
        setFilterClientes(statePrev => ({ ...statePrev, ...obj }));
    };

    const addMessage = (value) => {
        if (formSendSMS.getFieldValue('somentePush') === true)
            setMensagem(value);
        else if (value.length < maxLengthTextArea)
            setMensagem(value);
    }

    const addLink = () => {
        if (formSendSMS.getFieldValue('msmLink')) {
            let link = (!formSendSMS.getFieldValue('msmDescricao') ? "@Link" : formSendSMS.getFieldValue('msmDescricao') + "@Link");
            if (formSendSMS.getFieldValue('somentePush') === true) {
                formSendSMS.setFieldsValue(
                    { msmDescricao: link }
                )
                setMensagem(link);
            }
            else if (link.length < maxLengthTextArea) {
                formSendSMS.setFieldsValue(
                    { msmDescricao: link }
                )
                setMensagem(link);
            }
        }
    }

    const verifySMS = () => {
        let sms = formSendSMS.getFieldValue('msmDescricao') ? formSendSMS.getFieldValue('msmDescricao') : "";
        if (sms.length > maxLengthTextArea) {
            let value = sms.substring(0, maxLengthTextArea);
            formSendSMS.setFieldsValue(
                { msmDescricao: value }
            )
            setMensagem(value);
        }
    }
    const [initialValue, setInitialValue] = useState('');
    const openModalSummernote = () => {
        let value = messageCustom !== null ? messageCustom : '';
        setInitialValue(value);
        setModalSummernote(true)
    }

    const onSaveMessageCustom = (value) => {
        formSendSMS.setFieldsValue({ msmMensagemCustom: value });
        setMessageCustom(value);
        setModalSummernote(false)
    }

    return (
        <Drawer title="Enviar Mensagem"
            width="80%"
            destroyOnClose={true}
            visible={exibirDrawer}
            onClose={onClose}
            footer={
                <div className="right">
                    <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button" className="m-r-8">
                        Cancelar
                    </Button>
                    <Button loading={loading} icon={<SendOutlined />} size="large" type="primary" htmlType="submit" onClick={() => formSendSMS.submit()}>
                        Enviar
                    </Button>
                </div>
            }>
            <div className="App">
                <Row align="top" gutter={[8, 8]}>
                    <Col sm={8}>
                        <div>
                            <Card className="m-t-20 b-c-s">
                                <div className="text-center">
                                    <div className="f-18 font-weight primary-color">{saldoSMS}</div>
                                    <div className="f-12">Saldo atual de mensagens (SMS).</div>
                                </div>
                            </Card>
                            <Form layout="vertical"
                                name="formSendSMS"
                                form={formSendSMS}
                                onFinish={onFinish}>
                                <Form.Item name="ctmId" hidden />
                                <Form.Item name="msmLink" hidden />
                                <Form.Item name="msmMensagemCustom" hidden />

                                {dataRegister.ctmId === 0 && <Form.Item label="Mensagem Modelo (Opcional)" name="msmId">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Selecione uma mensagem modelo"
                                        onChange={onChangeMessage}>

                                        {listaMensagemMarketing.map(res => (
                                            <Select.Option key={res.msmId} value={res.msmId}>{`${res.msmId} - ${res.msmTitulo}`}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>}

                                <Form.Item label="Título da Mensagem (Apresentado no Push)" name="msmTitulo" rules={[{ required: true, message: 'Um título deve ser informado!' }]}>
                                    <Input placeholder="Título..." />
                                </Form.Item>

                                <Form.Item label="Preview Mensagem (Mensagem reduzida)" name="msmDescricao"
                                    rules={[{ required: true, message: 'Uma mensagem deve ser informada!' }]}>
                                    <Input.TextArea showCount maxLength={checkedPush ? '' : maxLengthTextArea} placeholder="Mensagem..."
                                        onChangeCapture={(e) => addMessage(e.target.value)}
                                        className="textarea" rows={6} />
                                </Form.Item>
                                {!checkedPush && <span className="right">({mensagem.length}/{maxLengthTextArea})</span>}

                                <Collapse className="p-relative p-0" defaultActiveKey={openLink ? '1' : ''} ghost destroyInactivePanel={true}>
                                    <Collapse.Panel key="1" showArrow={false} extra={
                                        <Button type="link" size="large" >
                                            <LinkOutlined className="f-16" />
                                            <span className="f-14 g-o-b">
                                                Link
                                            </span>
                                        </Button>
                                    }>
                                        <Row align="middle" gutter={[8, 8]}>
                                            <Col sm={18}>
                                                <Form.Item label="Adicione link na mensagem (@Link) " name="msmLink" >
                                                    <Input placeholder="Informe o link aqui..." />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={2}>
                                                <Button className="m-t-21" type="primary"
                                                    icon={<PlusOutlined />}
                                                    onClick={addLink}>
                                                    Adicionar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Collapse.Panel>
                                </Collapse>

                                {totalRegister > 0 &&
                                    <Card className="m-t-20 b-c-s">
                                        <div className="text-center">
                                            <div className="f-18 font-weight primary-color">{totalRegister}</div>
                                            <div className="f-12"> Cliente(s) receberão esta mensagem!</div>
                                        </div>
                                    </Card>
                                }
                                <Row justify="space-between" className="m-t-20">
                                    <Col>
                                        <Form.Item
                                            label="Enviar somente push"
                                            name="somentePush"
                                            valuePropName="checked">
                                            <Switch hecked={checkedPush} onChange={(e) => {
                                                setCheckedPush(e);
                                                if (checkedPush)
                                                    verifySMS();
                                            }} />
                                        </Form.Item>
                                    </Col>
                                    {checkedPush &&
                                        <Col>
                                            <Button
                                                type="primary"
                                                onClick={openModalSummernote}>
                                                {messageCustom !== null ? 'Editar mensagem Personalizada' : 'Cadastrar mensagem Personalizada'}
                                            </Button>
                                        </Col>}
                                </Row>
                                {messageCustom !== null &&
                                    <Row>
                                        <Col span="24">
                                            <Card
                                                title="Preview mensagem personalizada"
                                                className="m-t-20 b-c-s">
                                                <div dangerouslySetInnerHTML={{ __html: messageCustom }}></div>
                                            </Card>
                                        </Col>
                                    </Row>}
                            </Form>
                        </div>
                    </Col>
                    <Col sm={1}>
                    </Col>
                    <Col sm={15}>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col align="middle" sm={12} >
                                <Button onClick={() => { setOpenFilter(true); setPesquisaCliente("") }} icon={<UsergroupAddOutlined />} size="middle" type="primary">
                                    Selecionar Grupo de Clientes
                                </Button>
                            </Col>
                            <Col align="middle" sm={12}>
                                <Button onClick={() => { setVisibleSelectClient(!visibleSelectClient) }} icon={<UserAddOutlined />} size="middle" type="primary" >
                                    Selecionar Cliente
                                </Button>
                            </Col>
                        </Row>

                        <ModalAddCliente visible={visibleSelectClient} filter={filterClientes} setFilter={setFilterClientes} onCloseModal={() => setVisibleSelectClient(false)} />

                        {totalRegister > 0 && <Row align="middle" gutter={[8, 8]}>
                            <Col align="middle" sm={20}>
                                <Input.Search allowClear="true" placeholder="Pesquise dentro da lista de clientes selecionados..."
                                    value={pesquisaCliente} onChange={e => searchClient(e.target.value)}
                                    enterButton />
                            </Col>
                            <Col sm={4} align="center">
                                <Button onClick={() => {

                                    Modal.confirm({
                                        title: 'Atenção',
                                        icon: <ExclamationCircleOutlined />,
                                        content: 'Tem certeza que deseja limpar a lista de clientes selecionados?',
                                        okText: 'Sim',
                                        cancelText: 'Cancelar',
                                        onOk: () => {
                                            setFilterClientes({});
                                            setTotalRegister(0);
                                        }
                                    });
                                }} icon={<ClearOutlined />} size="small" type="default" >
                                    Limpar
                                </Button>
                            </Col>
                        </Row>}
                        <div className="tabela">
                            <Table loading={loadingClientes}
                                locale={{
                                    emptyText: (
                                        <Row>
                                            <Col className="textEmpty" span={24}>
                                                <ExclamationCircleOutlined /> Nenhum cliente selecionado...
                                            </Col>
                                        </Row>)
                                }}
                                columns={
                                    [
                                        {
                                            title: 'Código',
                                            width: 105,
                                            align: 'center',
                                            fixed: 'left',
                                            render: ({ cliId, cliCodigo }) => (
                                                <Tag color={'processing'}>
                                                    <b>#{cliCodigo === 0 ? cliId : cliCodigo}</b>
                                                </Tag>
                                            ),
                                        },
                                        {
                                            title: 'Cliente',
                                            fixed: 'left',
                                            render: ({ cliNome }) => (
                                                <div>
                                                    <b>{cliNome}</b>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Data de Cadastro',
                                            fixed: 'left',
                                            render: ({ cliDataCadastro }) => (
                                                <div>
                                                    <span>{moment(cliDataCadastro).format('DD/MM/YYYY HH:mm')}</span>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Data de Nascimento',
                                            fixed: 'left',
                                            render: ({ cliDataNascimento }) => (
                                                <div>
                                                    <span>{moment(cliDataNascimento).format('DD/MM/YYYY')}</span>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Telefone',
                                            fixed: 'left',
                                            render: ({ cliCelular }) => (
                                                <div>
                                                    <span>{cliCelular != null ? MaskFormat(cliCelular, "(00) 00000-0000") : "Telefone não informado."}</span>

                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Opções',
                                            dataIndex: 'operation',
                                            render: (text, record) =>
                                            (
                                                <Popconfirm title="Tem certeza que deseja excluir este item?" onConfirm={() => onDeleteClient(record)}>
                                                    {/* <a>Excluir</a> */}
                                                    <Button icon={<DeleteOutlined />} size="small" type="dashed" >
                                                        Excluir
                                                    </Button>
                                                </Popconfirm>
                                            ),
                                        }
                                    ]}
                                pagination={{
                                    current: numPage,
                                    pageSize: numRegister,
                                    total: listClientes.total,
                                    onChange: (pg) => setNumPage(pg),
                                    onShowSizeChange: (current, page) => setNumRegister(page)
                                }}
                                dataSource={listClientes.data}
                            />
                        </div>
                        {!(listClientes.data?.length === 0 && verifyClientes) ||
                            <div className="error" align="center">
                                <div>Selecione a lista de clientes para receber a mensagem.</div>
                            </div>
                        }
                    </Col>
                </Row>
                <FilterClients exibirDrawer={OpenFilter} setFilterCliente={setFilterClientes} fecharDrawer={() => setOpenFilter(false)}></FilterClients>
            </div>

            <ModalSummernote message={initialValue} display={modalSummernote} onCloseModal={() => setModalSummernote(false)} onSave={onSaveMessageCustom} />
        </Drawer >
    );
}