import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import "../../App.less";
import ListRanking from '../../components/ListRanking/ListRanking'

export default function ModalRanking({ show, onClose }) {

    const formatDate = (date) => {
        var _date = new Date(date),
            day = _date.getDate().toString(),
            dayF = (day.length === 1) ? '0' + day : day,
            month = (_date.getMonth() + 1).toString(),
            monthF = (month.length === 1) ? '0' + month : month,
            yearF = _date.getFullYear();

        return [dayF, monthF, yearF].join("/");
    }

    const [period, setPeriod] = useState(() => {

        let date = new Date();
        let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);

        let timeFirst = formatDate(firstDate);
        let currentDate = formatDate(date);
        return `${timeFirst} - ${currentDate}`;
    });

    const onCloseModal = () => {
        onClose();
    };

    return (
        <Modal centered
            width="70%"
            title={`Ranking dos atendentes avaliações no período: ${period}`}
            visible={show}
            destroyOnClose={true}
            onCancel={onCloseModal}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}>
            <ListRanking />
        </Modal>
    );
}