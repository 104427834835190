import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, Form, Input, Button, Table, Tag, Menu, Select } from "antd";
import {
    QuestionCircleOutlined, DeleteFilled, EditOutlined, PrinterFilled, StarFilled, UserOutlined,
    OrderedListOutlined
} from "@ant-design/icons";
import moment from 'moment';
import "../../App.less";
import DrawerComentario from "./drawerComentario";
import api from '../../services/api';
import ModalRanking from '../../components/modals/modalRanking';
import { Stars } from '../../components';

export default function Avaliacoes() {

    const [listAvaliacao, setListAvaliacoa] = useState({});
    const [estatistica, setEstatistica] = useState({});
    const [page, setPage] = useState(1);
    const [load, setLoad] = useState(false);
    const [numPage, setNumPage] = useState(10);
    const [search, setSeach] = useState("");
    const [order, setOrder] = useState("abastecimento.aba_data_cadastro_avaliacao desc");
    const [openModal, setOpenModal] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [recordDrawer, setRecordDrawer] = useState({});

    useEffect(() => loadAvaliacoes(), [search, numPage, page, order]);
    function loadAvaliacoes() {

        api.get(`Abastecimento/GetFull?numRegister=${numPage}&numPage=${page}&filter=${search}&order=${order}`).then(res => {
            setLoad(true);
            if (res.status === 200) {
                setListAvaliacoa(res.data);
                console.log("dados carregados avaliações", res.data);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setLoad(false));
    }

    useEffect(() => {
        api.get(`Abastecimento/GetEstatisticas`).then(result => {
            if (result.status === 200) {
                setEstatistica(result.data.result);
            }
        }, error => { console.error(error.response); });
    }, []);

    const avaliate = (qtd) => {
        let result = [];
        let yellow = <StarFilled style={{ color: '#737373' }} />;
        let white = <StarFilled style={{ color: '#d9d9d9' }} />;
        for (let index = 0; index < qtd; index++) {
            result.push(yellow);
        }
        for (let index = 0; index < 5 - qtd; index++) {
            result.push(white);

        }
        return result;
    }

    const menuTable = (
        <Menu className="text-right">
            <Menu.Item key="1">
                <Button type="link" icon={<DeleteFilled />}> Remover </Button>
            </Menu.Item>
            <Menu.Item key="2">
                <Button type="link" icon={<PrinterFilled />}> Editar </Button>
            </Menu.Item>
        </Menu>
    );

    const currencyFormat = (value) => {
        if (!value)
            return 0
        return value.toFixed(1).replace('.', ',')
    }

    const openDrawer = (record) => {
        setShowDrawer(true);
        setRecordDrawer(record);
    }

    return (
        <div className="p-10">
            <div>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={3}>
                        <span>
                            <h2 className="title-dashboard m-t-p-75">Avaliações</h2>
                        </span>
                    </Col>
                    <Col span="5">
                        <ModalRanking show={openModal} onClose={() => setOpenModal(false)} />
                        <Button type="primary" icon={<OrderedListOutlined />} size="large" onClick={() => setOpenModal(true)}>
                            Ranking dos atendentes
                        </Button>
                    </Col>
                    <Col span={16} className="text-right">
                        <div>
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={24}>
                                    <span>
                                        <h2 className="title-dashboard clearH3">Média das Avaliações</h2>
                                    </span>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[8, 8]} className="text-right">
                                <Col span={15}></Col>
                                <Col span={3} className="text-center m-t-p-20">
                                    <div className="rateNumber">
                                        {currencyFormat(estatistica.media)}
                                    </div>
                                    <div>
                                        Total de avaliações: <UserOutlined />
                                    </div>
                                    <div>{estatistica.total}</div>
                                </Col>
                                <Col span={6} className="text-right">
                                    <div>
                                        <div>
                                            <span className="p-absolute">5</span>
                                            <progress value={estatistica.nota5} max="100" className="rate5"></progress>
                                        </div>
                                        <div>
                                            <span className="p-absolute">4</span>
                                            <progress value={estatistica.nota4} max="100" className="rate4"></progress>
                                        </div>
                                        <div>
                                            <span className="p-absolute">3</span>
                                            <progress value={estatistica.nota3} max="100" className="rate3"></progress>
                                        </div>
                                        <div>
                                            <span className="p-absolute">2</span>
                                            <progress value={estatistica.nota2} max="100" className="rate2"></progress>
                                        </div>
                                        <div>
                                            <span className="p-absolute">1</span>
                                            <progress value={estatistica.nota1} max="100" className="rate1"></progress>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                <Form layout="vertical">
                    <Row align="middle" gutter={[8, 8]}>
                        <Col sm={18}>
                            <Form.Item>
                                <div>
                                    <label> Pesquisar por </label>
                                    <Tooltip title="Mensagem">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>
                                <Input.Search allowClear="true" placeholder="Avaliações, cliente..." value={search}
                                    onChange={e => setSeach(e.target.value)}
                                    enterButton />
                            </Form.Item>
                        </Col>
                        <Col sm={6}>
                            <div>
                                <Form.Item label="Ordenar por">
                                    <Select showSearch options={[
                                        { label: "Nome A - Z", value: "clientes.cli_nome asc" },
                                        { label: "Nome Z - A", value: "clientes.cli_nome desc" },
                                        { label: "Data Crescente", value: "abastecimento.aba_data_cadastro_avaliacao " },
                                        { label: "Data Decrescente", value: "abastecimento.aba_data_cadastro_avaliacao desc" }
                                    ]} placeholder="Selecione a Ordenação" value={order} onChange={(val) => { setOrder(val) }} />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <div className="tabela">
                    <Table loading={load} columns={
                        [
                            {
                                title: 'Código',
                                width: 105,
                                align: 'center',
                                fixed: 'left',
                                render: ({ aba_id }) => (
                                    <Tag color={'green'}>
                                        <b>#{aba_id}</b>
                                    </Tag>
                                ),
                            },
                            {
                                title: 'Cliente',
                                fixed: 'left',
                                render: ({ cli_nome }) => (
                                    <div>
                                        <b>{cli_nome}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Avaliação',
                                align: 'center',
                                render: ({ aba_avaliacao }) => (
                                    <div className="">
                                        {/* {avaliate(aba_avaliacao).map((x, i) => (<span style={{ paddingLeft: 2 }} key={i}>{x}</span>))} */}
                                        <Stars  quantidade={aba_avaliacao} />
                                    </div>
                                ),
                            },
                            {
                                title: 'Observação',
                                fixed: 'left',
                                render: ({ aba_obsavaliacao }) => (
                                    <div>
                                        <b className="w-s-n">{aba_obsavaliacao}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Descrição',
                                fixed: 'left',
                                render: ({ aba_descricao }) => (
                                    <div>
                                        <p className="w-s-n">{aba_descricao}</p>
                                    </div>
                                ),
                            },
                            {
                                title: 'Data de avaliação',
                                fixed: 'left',
                                align: 'center',

                                render: ({ aba_data_cadastro_avaliacao }) => (
                                    <div>
                                        <b>{moment(aba_data_cadastro_avaliacao).format('DD/MM/YYYY HH:mm')}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Operações',
                                dataIndex: '',
                                align: 'center',
                                fixed: 'right',
                                key: 'x',
                                render: (record) => (
                                    <>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button onClick={() => openDrawer(record)} icon={<EditOutlined />}>Responder</Button>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            }
                        ]}
                        pagination={{
                            current: page,
                            pageSize: numPage,
                            total: listAvaliacao.total,
                            onChange: (pg) => setPage(pg),
                            onShowSizeChange: (current, page) => setNumPage(page)
                        }}
                        dataSource={listAvaliacao.data}
                    />
                </div>
            </div>
            <DrawerComentario show={showDrawer} record={recordDrawer} onCloseDrawer={() => setShowDrawer(false)} />
        </div>
    );

}