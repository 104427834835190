import React from "react";
import { Row, Col, Form, Input } from "antd";
import "../../App.less";

export default function TabSms() {
    return (
        <div className="pages-col">
            <Row align="middle" gutter={[8, 8]}>
                <Col span={24}>
                    <Form.Item label="Mensagem" name="msmDescricao" rules={[{ required: true, message: 'A mensagem deve ser informada.' }]}>
                        <Input.TextArea rows="4" placeholder="Informe a mensagem" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}