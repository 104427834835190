import React, { useState, useEffect } from "react";
import api from '../../services/api';

export default function Index({host}) {

    const [linkSuperset, setLinkSuperset] = useState("");

    useEffect(() => {
        api.post(`SuperSet/GetURL`).then(res => {
            if (res.status === 200) {
                console.log('res.data  dados superset :>> ', res.data);
                let { urlSuperset, jwt } = res.data;
                let url = `${urlSuperset}login/?jwt=${encodeURIComponent(jwt)}&redirect=${encodeURIComponent(urlSuperset + host)}`;
                // let url = `${urlSuperset}login/?jwt=${encodeURIComponent(jwt)}`;
                console.log('url :>> ', url);
                setLinkSuperset(url);
            }
        }, err => {
            console.log(err.response)
        });
    }, [])

    return (
        <div style={{
            position: "relative",
            height: 0,
            overflow: "hidden",
            maxWidth: "100%",
            paddingBottom: "56.25%"
        }}>
            <style>
                {`.dentroConteudo > div {
                       top: 0; 
                       margin: 0;
                       padding: 0;
                       height: 100%;
                    }`}
                {`.dentroConteudo .material-icons {
                        width: 100%;
                        font-size: 52px;
                        margin-bottom: 5px;
                    }`}
                {`.dentroConteudo nav {
                        display:none;
                        visibility:hidden;
                    }`}
            </style>

            <iframe title="SuperSet" src={linkSuperset} style={{ position: "absolute", "top": 0, left: 0, width: "100%", height: "100%" }} frameborder="0" allowfullscreen> </iframe>
        </div>
    );
}