import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, Form, Input, Table, Tag, Button } from "antd";
import {
    QuestionCircleOutlined, EditOutlined
} from "@ant-design/icons";
import moment from 'moment';
import "../../App.less";
import api from '../../services/api';
import { useForm } from "antd/lib/form/Form";
import { SelectImage } from '../../components'
import DrawerAwards from './drawerAwards';
import { SelectMensagem } from '../../components';

export default function Index() {

    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [searchProduct, setSearchProduct] = useState('');
    const [listProducts, setListProducts] = useState([]);

    const [openDrawerAward, setOpenDrawerAward] = useState(false);
    const [recordDrawer, setRecordDrawer] = useState({});


    useEffect(() => loadData(), []);

    useEffect(() => {
        if (searchProduct !== '') {
            let list = [...listProducts];
            list = list.filter((item, i) => {
                return item.proDescricao.toLowerCase().indexOf(searchProduct.toLowerCase()) > -1 || item.proCodigo.indexOf(searchProduct.toLowerCase()) > -1;
            })
            setListProducts(list);
        } else
            loadData();
    }, [searchProduct]);

    const loadData = () => {
        api.get(`Produtos/GetPremios`).then((result) => {
            if (result.status === 200)
                setListProducts(result.data)
        }).catch((err) => {
            console.log('err :>> ', err);
        });
    }

    const [loadMessage, setLoadMessage] = useState(false);
    const [filterMensagem, setFilterMensagem] = useState("");
    const [listaMensagem, setListaMensagem] = useState([]);

    useEffect(() => loadMensagens(), [filterMensagem]);
    function loadMensagens() {
        setLoadMessage(true);
        api.get(`MensagemMarketing/GetAll?numRegister=5000&numPage=1&filter=${filterMensagem}&active=1`).then(res => {
            console.log('res :>> ', res);
            if (res.status === 200) {
                setListaMensagem(res.data.data);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setLoadMessage(false));
    }

    const onChangeMensagem = (msmId, proId) => {
        let data = { msmId, proId };
        api.post(`MensagemProduto/Save`, data).then((result) => {
            if (result.status === 200) {
                console.log('Mensagem relacionada com sucesso! ');
            }
        }).catch((err) => {
            console.log('err :>> ', err);
        });
    }

    const onSaveImage = async (proCodigo, value) => {
        if (value.length > 0 && value[0].status !== 'uploading') {

            let base64 = await getBase64(value[0].originFileObj)
            let vetor = base64.split(',');
            let type = value[0].type.split('/');
            let data = {
                img: vetor[1],
                name: `${proCodigo}1`,
                extension: `.${type[1]}`,
                ean: null
            };

            api.post(`Arquivo/Upload`, data).then((result) => {
                if (result.status === 200) {
                    console.log('imagem salva com sucesso ');
                }
            }).catch((err) => {
                console.log('err :>> ', err);
            });

        }
    }

    const onRemoveImage = (proId) => {
        api.delete(`Imagens/DeteleImgProduto?proId=${proId}&sequencia=2`).then((result) => {
            if (result.status === 200) {
                console.log('imagem deletada com sucesso ');
            }
        }).catch((err) => {
            console.log('err :>> ', err);
        });
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    // const openDrawer = (record) => {
    //     setOpenDrawerAward(true);
    //     setRecordDrawer(record);
    // }

    return (
        <div className="p-10">
            <div>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={6}>
                        <span>
                            <h2 className="title-dashboard">Lista de prêmios</h2>
                        </span>
                    </Col>
                </Row>
                <Form layout="vertical" form={form}>
                    <Row align="middle" gutter={[8, 8]}>
                        <Col sm={24}>
                            <Form.Item>
                                <div>
                                    <label> Pesquisar por </label>
                                    <Tooltip title="Código, Nome, CPF, CEP, Cidade">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>
                                <Input.Search allowClear="true" placeholder="Código, Descrição..."
                                    value={searchProduct} onChange={e => setSearchProduct(e.target.value)}
                                    enterButton />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <div className="tabela">
                    <Table loading={loading}
                        columns={
                            [
                                {
                                    title: 'Imagem',
                                    width: 50,
                                    render: ({ proId, proCodigo }) => (
                                        <Row justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <SelectImage proId={proId} onRemove={() => onRemoveImage(proId)} onSave={(value) => onSaveImage(proCodigo, value)} />
                                            </Col>
                                        </Row>
                                    ),
                                },
                                {
                                    title: 'Código',
                                    width: 50,
                                    render: ({ proCodigo }) => (
                                        <Tag color={'processing'}>
                                            <b>#{proCodigo}</b>
                                        </Tag>
                                    ),
                                },
                                {
                                    title: 'Descrição',
                                    width: 100,
                                    render: ({ proDescricao }) => (
                                        <div>
                                            <b>{proDescricao}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Data cadastro',
                                    align: 'center',
                                    render: ({ proDataCadastro }) => (
                                        <div>
                                            <b>{proDataCadastro != null ? moment(proDataCadastro).format('DD/MM/YYYY HH:mm') : ''}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Pontos',
                                    align: 'center',
                                    render: ({ proPontos }) => (
                                        <div>
                                            <span>{proPontos}</span>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Cad. Mensagem',
                                    dataIndex: '',
                                    align: 'center',
                                    key: 'x',
                                    render: (record) => (
                                        <Row justify="center" gutter={[5, 0]}>
                                            <Col span="18">
                                                <SelectMensagem
                                                    msmId={record.msmId}
                                                    list={listaMensagem}
                                                    onChange={values => onChangeMensagem(values, record.proId)} />
                                            </Col>
                                        </Row>
                                    )
                                }
                            ]}
                        dataSource={listProducts}
                    />
                </div>
            </div>
            {/* <DrawerAwards show={openDrawerAward} onCloseDrawer={()=>setOpenDrawerAward(false)} record={recordDrawer} /> */}
        </div>
    );

}