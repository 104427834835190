import React from "react";
import { StarFilled, } from '@ant-design/icons';

export default function Stars({ quantidade }) {

    const avaliate = (qtd) => {
        let result = [];
        let yellow = <StarFilled style={{ color: '#737373' }} />;
        let white = <StarFilled style={{ color: '#d9d9d9' }} />;
        for (let index = 0; index < qtd; index++) {
            result.push(yellow);
        }
        for (let index = 0; index < 5 - qtd; index++) {
            result.push(white);

        }
        return result;
    }

    return (
        <div>
            {avaliate(quantidade).map((x, i) => (<span style={{ paddingLeft: 2 }} key={i}>{x}</span>))}
        </div>
    );
}