import React, { useState } from "react";
import { Modal, Button, } from "antd";
import { ExclamationCircleOutlined, } from "@ant-design/icons";
import ListClientes from './listClientes';

export default function ModalAddCliente({ visible, onCloseModal, filter, setFilter }) {

    const [listSelected, setListSelected] = useState([]);

    const cancel = () => {
        if (listSelected.length > 0) {
            Modal.confirm({
                title: 'Atenção',
                icon: <ExclamationCircleOutlined />,
                content: 'Deseja sair sem salvar?',
                okText: 'Sim',
                cancelText: 'Cancelar',
                onOk: () => {
                    onCloseModal();
                }
            });
        } else {
            onCloseModal();
        }
    }

    const onSave = () => {
        if (listSelected.length > 0) {
            let obj = Object.keys(filter).length > 0 ? { ...filter } : {};

            if (Object.keys(filter).findIndex(x => x === "listClientsAdd") === -1)
                obj.listClientsAdd = [];

            obj.listClientsAdd.push(...listSelected);

            if (Object.keys(filter).findIndex(x => x === "listClientsRemove") > -1) {

                for (var i in obj.listClientsAdd) {
                    let item = obj.listClientsAdd[i];
                    let index = obj.listClientsRemove.findIndex(x => x === item);
                    if (index > -1) {
                        obj.listClientsRemove.splice(index, 1)
                    }
                }
            }

            // setFilter(setPrev => ({ ...setPrev, ...obj }));
            setFilter(obj);
            setListSelected([]);
        }
        onCloseModal();
    }

    return (
        <Modal centered
            title="Adicionar clientes"
            visible={visible}
            destroyOnClose={true}
            onCancel={cancel}
            width={920}
            footer={[
                <Button size="middle" key="back" onClick={cancel}>
                    Cancelar
                </Button>,
                <Button size="middle" type="primary" onClick={onSave}>
                    Salvar
                </Button>,
            ]}>
            <ListClientes listSelected={listSelected} setListSelected={setListSelected} filter={filter} setFilter={setFilter} />
        </Modal>
    );
}