export const TOKEN_KEY = "@GestorWeb-Token";
export const EMPRESA_KEY = "88e4241c-9c1f-11ea-acf4-3085a9e96739";
export const LOCAL_KEY = "@GestorWeb-Local";
export const USER_KEY = "@GestorWeb-User";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getEmpresa = () => localStorage.getItem(EMPRESA_KEY);
export const getLocal = () => localStorage.getItem(LOCAL_KEY);
export const urlWebSite = () => "http://localhost:3000/login/";
export const urlApi = process.env.REACT_APP_PUBLIC_URL || 'http://localhost:5000/api/';
// export const urlApi = process.env.REACT_APP_PUBLIC_URL || 'http://mobilapiteste.gestorweb.com.br/api/';

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const setUser = user => {
  localStorage.setItem(USER_KEY, user);
};

export const setEmpresa = empresa => {
};

export const setLocal = local => {
  localStorage.setItem(LOCAL_KEY, local);
};

export const logout = () => {
  // localStorage.removeItem(TOKEN_KEY);
  // localStorage.removeItem(EMPRESA_KEY);
  // localStorage.removeItem(LOCAL_KEY);
  localStorage.clear();
  window.location = "/"
};

export const getUserInfo = () => {
  let token = localStorage.getItem(TOKEN_KEY);
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
