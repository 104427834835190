import React from 'react';
import { Row, Col, Typography, Carousel } from 'antd';
import { EnvironmentOutlined, PhoneOutlined, DesktopOutlined } from '@ant-design/icons';

import "./login.less";
import logo from '../../assets/logo.png';

export default function SlideLogin() {

    const { Link } = Typography;

    return (
        <div>
            <div className="colLogo flexContent flexDirectionColumn">
                <Row align="middle">
                    <Col span={12}>
                        <Row align="middle">
                            <Col span={24}>
                                <img src={logo} width="70" alt="Abase Sistemas e Soluções" />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row align="middle" className="text-right">
                            <Col span={24}>
                                Abase Sistemas e Soluções LTDA
                            </Col>
                            <Col span={24}>
                                <DesktopOutlined /> 
                                <Link href="http://www.gestorweb.com.br/" target="_blank" underline> www.gestorweb.com.br </Link> | <PhoneOutlined /> (55) 3535-4900
                            </Col>
                            <Col span={24}>
                                <EnvironmentOutlined /> Três de Maio - RS - 98910-000
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Carousel effect="fade" dots={false} autoplay>
                <div>
                    <div className="col-banners" style={{backgroundImage: `url(https://image.freepik.com/fotos-gratis/conceito-de-homens-de-negocios-de-aperto-de-mao_53876-31214.jpg)`}}></div>
                </div>
                <div>
                    <div className="col-banners" style={{backgroundImage: `url(https://image.freepik.com/fotos-gratis/area-de-trabalho-do-office-com-laptop-e-analitica_23-2148174089.jpg)`}}></div>
                </div>
                <div>
                    <div className="col-banners" style={{backgroundImage: `url(https://image.freepik.com/fotos-gratis/o-inspetor-financeiro-e-o-secretario-fazem-relatorio-calculam-ou-verificam-o-saldo-documento-de-verificacao-do-inspetor-do-servico-de-receita-federal-conceito-de-auditoria_1423-126.jpg)`}}></div>
                </div>
            </Carousel>
        </div>
    );

}