import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, DatePicker } from "antd";
import {
    SearchOutlined, ClearOutlined
} from "@ant-design/icons";
import "../../App.less";
import Chart from "react-google-charts";
import api from '../../services/api';
import { useForm } from "antd/lib/form/Form";
import moment from 'moment';

export default function ModalCharts({ isModalVisible, setVisible }) {

    const [form] = useForm();
    const [width, setWidth] = useState(1000);
    const [height, setHeight] = useState(400);
    const [list, setList] = useState([]);

    useEffect(() => {
        let data = [moment(new Date(new Date().setDate(new Date().getDate() - 30))), moment(new Date())];
        form.setFieldsValue({ periodo: data });
        searchCountClinet();
    }, [form, isModalVisible]);


    function searchCountClinet() {
        let values = form.getFieldsValue();
        if (!values.periodo)
            values.periodo = [moment(new Date(new Date().setDate(new Date().getDate() - 30))), moment(new Date())];

        let periodo = values.periodo.map((x) => (x.format('MM/DD/YYYY')));
        api.get(`Clientes/GetCountClientesDia?periodo=${periodo}`).then((res) => {
            if (res.status === 200) {
                setList(Array.from(res.data.map((x) => ([formatDate(x.dataCadastro), x.qtd]))));
                console.log("dados translates ", list);
            }
        });
    }

    function formatDate(date) {
        var _date = new Date(date),
            day = _date.getDate().toString(),
            dayF = (day.length === 1) ? '0' + day : day,
            month = (_date.getMonth() + 1).toString(),
            monthF = (month.length === 1) ? '0' + month : month,
            yearF = _date.getFullYear();

        return [dayF, monthF].join("/");
    }

    const onFinish = (values) => {
        searchCountClinet();
    }

    return (

        <Modal
            destroyOnClose={true}
            width={width + 100}
            height={height + 100}
            title="Fluxo de clientes cadastrados"
            visible={isModalVisible}
            cancelText="Fechar"
            onCancel={() => setVisible(false)}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            className="t-59"
        >
            <Form form={form} onFinish={onFinish}>
                <Row align="middle" justify="space-between" gutter={[8, 8]}>
                    <Col span={8}>
                        <Form.Item
                            label="Alterar período de consulta "
                            name="periodo">
                            <DatePicker.RangePicker format="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<SearchOutlined />}
                            onClick={() => { }}>
                            Pesquisar
                            </Button>
                    </Col>
                </Row>
            </Form>

            { list.length > 0 && <Chart
                width={width}
                height={height}
                chartType="LineChart"
                loader={<div>Carregando dados...</div>}
                data={[
                    ['x', 'Clientes'],
                    ...list
                ]}
                options={{
                    hAxis: {
                        title: 'Clientes cadastrados nos últimos 30 dias',
                    },
                    vAxis: {
                        title: '',
                    },
                    legend: ''
                }}
                rootProps={{ 'data-testid': '1' }}
            />
            }

        </Modal>

    )
}