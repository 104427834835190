import React from 'react';
import { Route, Switch, HashRouter } from "react-router-dom";
import { Layout } from "antd";

import { MenuPage, HeaderPage } from "../../components";
import { Login, Clientes, Sms, SendSms, Avaliacoes, Awards, Dashboard} from "../";

const { Content } = Layout;

export default function Home() {

    return (
        <HashRouter>
            <div className="App">
                <Layout className="vh100">
                    <HeaderPage />
                    <Layout className="vh100 m-t-60">
                        <MenuPage />
                        <Layout className="site-layout minify_bar"> 
                            <Content>
                                <Switch>
                                    <Route exact path="/" component={Clientes} />
                                    <Route exact path="/login" component={Login} />                                    
                                    <Route exact path="/clientes" component={Clientes} />     
                                    <Route exact path="/sms" component={Sms} />  
                                    <Route exact path="/awards" component={Awards} />  
                                    <Route exact path="/sendSms" component={SendSms} />  
                                    <Route exact path="/avaliacoes" component={Avaliacoes} />  
                                    <Route exact path="/dashboard" component={Dashboard} />  
                                </Switch>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        </HashRouter>
    );    
}