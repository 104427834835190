import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Typography, Alert } from 'antd';
import { MailOutlined, ArrowLeftOutlined, SendOutlined } from '@ant-design/icons';
import api from '../../services/api';
import { urlWebSite } from '../../services/auth';

export default function EsqueciSenha({ onAcessar }) {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, [])

    const onFinish = values => {
        setLoading(true);
        let data = {
            baseUrl: urlWebSite(),
            email: values.email,
            nameSystem: "Sistema de Gerenciamento de Clientes - Posto Primeiro ",
            typeSystem: "GestorWeb"
        };
        api.post('Usuario/RecouveryEmail', data).then(res => {
            console.log({ res });
            if (res.status === 200) {
                setShowMessage(true);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setLoading(false));
    };


    return (
        <div className="m-t-15">
            <Typography.Text className="f-14">
                Informe seu email abaixo para recuperação de suas credenciais.
            </Typography.Text>
            <Form name="login" size="middle" form={form} onFinish={onFinish}>
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item name="email" rules={[{ required: true, message: 'Informe seu e-mail para recuperar sua senha.' }]}>
                            <Input placeholder="Informe seu e-mail para recuperar sua senha" prefix={<MailOutlined />} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="flexContent justifyContentBetween">
                            <Button htmlType="button" onClick={() => onAcessar()} >
                                <ArrowLeftOutlined /> Voltar
                                </Button>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    Recuperar Senha <SendOutlined />
                                </Button>
                            </Form.Item>
                        </div>
                    </Col>
                    {showMessage &&
                        <Col span={24}>
                            <Alert
                                message="Muito bem!"
                                description="Você deve receberá um e-mail, contendo orientações para a altereção de sua senha!"
                                type="success"
                                showIcon
                            />
                        </Col>}
                </Row>
            </Form>
        </div>
    );
}