import React from 'react';
import { Link } from "react-router-dom";
import { Form, Input, Tabs, Row, Col, Typography, Card } from 'antd';

import "./modulos.less";

export default function ModulosPage() {

    return (
        <div className="m-t-15">
            <Form size="middle" layout="vertical">
                <Row className="fixedPesquisa">
                    <Col span={24}>
                        <Row type="flex" justify="center">
                            <Col span={14}>
                                <Form.Item label="Pesquisar módulo">
                                    <Input.Search placeholder="Pesquisar..." enterButton />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <div className="col-modulo">
                <Tabs defaultActiveKey="1" size="large" type="card" centered>
                    <Tabs.TabPane tab="Varejo e Serviços" key="1">
                        <div className="rowPanel text-center">
                            <Row type="flex" align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col span={15}>
                                    <Row type="flex" align="middle" gutter={[16, 16]} justify="center">
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/vendas.png')} alt="Varejo e Serviços" />
                                                    <b>Vendas</b>
                                                </Link>
                                            </Card>                                            
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/compra.png')} alt="Compras" />
                                                    <b>Compras</b>
                                                </Link>
                                            </Card>                                            
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/pdv.png')} alt="PDV" />
                                                    <b>PDV</b>
                                                </Link>
                                            </Card>                                            
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/financeiro.png')} alt="Cotação de Vendas" />
                                                    <b>Cotação de Vendas</b>
                                                </Link>
                                            </Card>                                            
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/contabilidade.png')} alt="Faturamento Contratos" />
                                                    <b>Faturamento Contratos</b>
                                                </Link>
                                            </Card>                                            
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Industrial" key="2">
                        <div className="rowPanel text-center">
                            <Row type="flex" align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col span={15}>
                                    <Row type="flex" align="middle" gutter={[8, 16]} justify="center">
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/industria.png')} alt="Indústria" />
                                                    <b>Indústria</b>
                                                </Link>
                                            </Card>     
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/catalogoEletronico.png')} alt="Catálogo eletrônico" />
                                                    <b>Catálogo eletrônico</b>
                                                </Link>
                                            </Card>     
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>       
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Pedidos/O.S" key="3">
                        <div className="rowPanel text-center">
                            <Row type="flex" align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col span={15}>
                                    <Row type="flex" align="middle" gutter={[8, 16]} justify="center">
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/pedidosOs.png')} alt="Pedidos/O.S." />
                                                    <b>Pedidos/O.S</b>
                                                </Link>
                                            </Card>     
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div> 
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Financeiro" key="4">
                        <div className="rowPanel text-center">
                            <Row type="flex" align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col span={15}>
                                    <Row type="flex" align="middle" gutter={[8, 16]} justify="center">
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/aReceber.png')} alt="A Recebere" />
                                                    <b>A Receber</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/aPagar.png')} alt="A Pagar" />
                                                    <b>A Pagar</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/contaCorrente.png')} alt="Conta Corrente" />
                                                    <b>Conta Corrente</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/caixaBanco.png')} alt="Caixa e Bancos" />
                                                    <b>Caixa e Bancos</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Contábil/Fiscal" key="5">
                        <div className="rowPanel text-center">
                            <Row type="flex" align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col span={15}>
                                    <Row type="flex" align="middle" gutter={[8, 16]} justify="center">
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/contabil.png')} alt="Contábil" />
                                                    <b>Contábil</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/fiscal.png')} alt="Fiscal" />
                                                    <b>Fiscal</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/impostos.png')} alt="Impostos" />
                                                    <b>Impostos</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/sped.png')} alt="SPED" />
                                                    <b>SPED</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  
                    </Tabs.TabPane>                    
                    <Tabs.TabPane tab="Indicadores" key="6">
                        <div className="rowPanel text-center">
                            <Row type="flex" align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col span={15}>
                                    <Row type="flex" align="middle" gutter={[8, 16]} justify="center">
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/indicadores.png')} alt="Indicadores" />
                                                    <b>Indicadores</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  
                    </Tabs.TabPane>                   
                    <Tabs.TabPane tab="Logística" key="7">
                        <div className="rowPanel text-center">
                            <Row type="flex" align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col span={15}>
                                    <Row type="flex" align="middle" gutter={[8, 16]} justify="center">
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/mdfe.png')} alt="Conta Corrente" />
                                                    <b>MDF-e</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  
                    </Tabs.TabPane>                   
                    <Tabs.TabPane tab="Utilitários" key="8">
                        <div className="rowPanel text-center">
                            <Row type="flex" align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col span={15}>
                                    <Row type="flex" align="middle" gutter={[8, 16]} justify="center">
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/" className="flexContent flexDirectionColumn">
                                                    <img src={require('../../assets/utilitarios.png')} alt="Conta Corrente" />
                                                    <b>Utilitários</b>
                                                </Link>
                                            </Card>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
}