import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Typography, notification, Alert } from 'antd';
import { UnlockFilled, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import api from '../../services/api';

export default function RecuperarSenha({ onAcessar, hash, email, typeSystem }) {

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);


    useEffect(() => {
        form.resetFields();
    }, [])

    const onFinish = values => {
        setLoading(true);
        let data = {
            email: email,
            password: values.confirmPassword,
            hash: hash
        };
        api.post('Usuario/AlterPass', data).then(res => {
            console.log({ res });
            if (res.status === 200) {
                setShowMessage(true);
                notification.success({ message: "Senha alterada!", description: 'Sua senha foi recuperada com sucesso!', duration: 5000 });
                
                if (typeSystem === "" && typeSystem === undefined) {
                    setTimeout(() => {
                        window.location = "/";
                    }, 4000);
                }
            } else {
                notification.error({ message: 'Falha ao tentar alterar sua senha', description: 'Verifique os dados informados e tente novamente.' });
            }
        }, err => {
            notification.error({ message: 'Falha ao tentar alterar sua senha', description: 'Verifique os dados informados e tente novamente.' });
            console.log(err.response)
        }).finally(() => setLoading(false));
    };



    return (
        <div className="m-t-15">

            <Form name="login" size="middle" form={form} onFinish={onFinish}>
                <Row align="middle" gutter={[0, 16]}>
                    {!showMessage && <>
                        <Typography.Text className="f-14">
                            Informe sua uma nova senha de acesso.
                        </Typography.Text>
                        <Col span={24}>
                            <Form.Item name="password"
                                rules={[
                                    { min: 6, message: 'Informe no mínimo de 6 caracteres para sua senha.' },
                                    { required: true, message: 'Informe sua nova senha para alteração.' }
                                ]}>
                                <Input.Password placeholder="Informe sua nova senha" prefix={<UnlockFilled />} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="confirmPassword"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Confirme sua nova senha para alteração.',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('As senhas informadas não coincidem!');
                                        },
                                    }),
                                ]}>
                                <Input.Password placeholder="Confirme sua nova senha" prefix={<UnlockFilled />} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <div className="flexContent justifyContentBetween">
                                <Button htmlType="button" onClick={() => typeSystem === "" || typeSystem === undefined ? (window.location = "/"):(window.history.back())} >
                                    <CloseOutlined /> Cancelar
                                </Button>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Salvar Nova Senha <CheckOutlined />
                                    </Button>
                                </Form.Item>
                            </div>
                        </Col>
                    </>
                    }

                    { showMessage && typeSystem === "" && typeSystem === undefined &&
                        <Col span={24}>
                            <Alert
                                message="Muito bem!"
                                description="Sua senha foi alterada com sucesso!"
                                type="success"
                                showIcon
                            />
                        </Col>
                    }

                    { showMessage && typeSystem !== "" && typeSystem !== undefined &&
                        <Col span={24}>
                            <Alert
                                message="Muito bem!"
                                description="Sua senha foi alterada com sucesso! Volte ao aplicativo para efetuar o login com sua nova senha."
                                type="success"
                                showIcon
                            />
                        </Col>
                    }

                </Row>
            </Form>
        </div>
    );
}