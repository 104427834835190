import React, { useEffect } from "react";
import { Form, Select } from 'antd';

export default function Index({ list, onChange, msmId = null }) {

    const [form] = Form.useForm();

    useEffect(() => {
        if (msmId) {
            form.setFieldsValue({ msmId: msmId });
        }
    }, [])

    return (
        <Form layout="vertical" form={form} >
            <Form.Item label="Mensagem Modelo:" name="msmId">
                <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    placeholder="Selecione uma mensagem"
                    onChange={onChange}>
                    {list.map(res => (
                        <Select.Option key={res.msmId} value={res.msmId}>{`${res.msmId} - ${res.msmTitulo}`}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    );
}