import React from "react";
import { Row, Col, Layout, Tooltip, Button } from "antd";
import { LogoutOutlined } from '@ant-design/icons';
import { logout } from '../../services/auth';
import "./index.less";
import logo from '../../assets/logoGestorWhite.png';


export default function HeaderPage() {

    return (
        <Layout.Header>
            <Row align="middle" justify="center">
                <Col span={14}>
                    <Row>
                        <Col span={5} className="text-center">
                            <img src={logo} className="logo" alt="Logo" />
                        </Col>
                    </Row>
                </Col>
                <Col span={10} className="text-right">
                    <Tooltip title="Sair" placement="bottom">
                        <Button type="link" size="large" onClick={() => logout()}>
                            <LogoutOutlined className="f-16" />
                            <span className="f-14 g-o-b">
                                Sair
                            </span>
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
        </Layout.Header>
    );
}