import React from "react";
import { Row, Col } from 'antd';
import "../../App.less";
import { SupersetFrame } from '../../components'

export default function Dashboard() {

    return (
        <div className="p-10">
            <div>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={24}>
                        <SupersetFrame host="superset/dashboard/30?standalone=true" />
                        <SupersetFrame host="superset/dashboard/31?standalone=true" />
                    </Col>
                </Row>
            </div>
        </div>
    );

}