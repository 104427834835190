import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, Form, Input, Table, Tag, Popover, Collapse, Button, DatePicker, Select, Checkbox, Radio } from "antd";
import {
    QuestionCircleOutlined, CheckOutlined, CloseOutlined, ArrowUpOutlined, ArrowDownOutlined, SearchOutlined, ClearOutlined, TrophyOutlined, ExportOutlined, LineChartOutlined
} from "@ant-design/icons";
import moment from 'moment';
import "../../App.less";
import api from '../../services/api';
import { MaskFormat } from '../../ValueObjects'
import { useForm } from "antd/lib/form/Form";
import SubTable from "./subTable";
import ModalCharts from "./modalCharts";
import formatNumber from '../../ValueObjects/formatNumber';
import ModalSorteio from "./modalSorteio";
import { saveAs } from 'file-saver';

export default function Clientes() {

    const [formSearch] = useForm();
    const [carregando, setCarregando] = useState(false);
    const [totais, setTotais] = useState({});
    const [openFilter, setOpenFilter] = useState(false);
    const [pesquisaCliente, setPesqueisaCliente] = useState("");
    const [listaClientes, setListaClientes] = useState([]);
    const [locais, setLocais] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [totalList, setTotalList] = useState(0);
    const [numPage, setNumPage] = useState(1);
    const [numRegister, setNumRegister] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [loadingSorteio, setLoadingSorteio] = useState(false);
    const [loadingExportExcel, setLoadingExportExcel] = useState(false);
    const [draw, setDraw] = useState({});
    const [qtdUseApp, setUseApp] = useState(0);
    const [qtdNotUseApp, setNotUseApp] = useState(0);


    const [openSorteio, setOpenSorteio] = useState(false);

    useEffect(() => {
        loadClientes();
    }, [numRegister, numPage, pesquisaCliente]);


    function loadClientes() {
        if (pesquisaCliente) setNumPage(1);

        setCarregando(true);
        getCidadesClientes();
        onFinishSearch();
    }


    const getCidadesClientes = () => {
        api.get('Clientes/GetCidadesClientes').then((result) => {
            if (result.status === 200) {
                setCidades(result.data);
            }
        }).catch((err) => {
            console.log('err :>> ', err);
        });
    }

    useEffect(() =>
        function getTotais() {
            api.get(`Clientes/GetTotais`).then(res => {
                if (res.status === 200) {
                    setTotais(res.data.result);
                }
            }, err => {
                console.log(err.response)
            });
        }, []);

    useEffect(() => {
        api.get(`Locais/GetRegiao`).then(res => {
            if (res.status === 200)
                setLocais(res.data);
        }, err => {
            console.log(err.response)
        });
    }, []);

    const onFinishSearch = (option = 0) => {

        let values = formSearch.getFieldsValue();
        let isRegister = '';

        if (values.isRegisterApp === 0)
            isRegister = 1;
        else if (values.isRegisterApp === 1)
            isRegister = 0;
        else
            isRegister = '';

        if (!!values.periodoCadastral)
            values.periodoCadastral = values.periodoCadastral.map((x) => (x.format('MM/DD/YYYY')));
        if (!!values.periodoNascimento)
            values.periodoNascimento = values.periodoNascimento.map((x) => (x.format('MM/DD/YYYY')));

        if (!!values.dataAbastecimento)
            values.dataAbastecimento = values.dataAbastecimento.map((x) => (x.format('MM/DD/YYYY')));

        let data = {
            numRegister: numRegister,
            numPage: numPage,
            filters: pesquisaCliente,
            periodoCadastral: values.periodoCadastral ? values.periodoCadastral.join() : '',
            periodoNascimento: values.periodoNascimento ? values.periodoNascimento.join() : '',
            local: values.locais? values.locais.join(): null,
            isRegisterApp: isRegister,
            melhorClient: values.bestCustomers ? 1 : 0,
            cidade: values.cidade,
            valor: values.valor ? values.valor : 0,
            dataAbastecimento: values.dataAbastecimento ? values.dataAbastecimento.join() : '',
        };

        switch (option) {
            case 0: // pesquisa de clientes
                setCarregando(true);
                api.post(`Clientes/GetAllFilters`, data).then(res => {
                    debugger
                    console.log('res.data :>> ', res.data);
                    if (res.status === 200) {
                        setListaClientes(res.data.dados.data.map((x) => ({ key: x.cliId, ...x })));
                        setTotalList(res.data.dados.total);
                        setUseApp(res.data.totalApp);
                        setNotUseApp(res.data.totalSemApp);
                    }
                }, err => {
                    console.log(err.response)
                }).finally(() => setCarregando(false));
                break;
            case 1: // sorteio cliente 
                setDraw({});
                setLoadingSorteio(true);
                api.post(`Clientes/GetClientSorteado`, data).then(res => {
                    if (res.status === 200) {
                        setDraw(res.data);
                    }
                }, err => {
                    console.log(err.response);
                }).finally(() => setLoadingSorteio(false));
                break;
            case 2: // export excel                 
                setLoadingExportExcel(true);
                api.post(`Clientes/GenerateExcel`, data).then(res => {
                    console.log('res :>> ', res);
                    if (res.status === 200) {
                        const byteArray = new Uint8Array(atob(res.data.toString()).split('').map(char => char.charCodeAt(0)));
                        saveFile(byteArray, "Clientes.xlsx");
                    }
                }, err => {
                    console.log(err.response)
                }).finally(() => setLoadingExportExcel(false));
                break;
            default:
                break;
        }

    };

    function saveFile(byteArray, fileName) {
        var blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, fileName);
    };
    const onPrize = () => {
        onFinishSearch(1);
        setOpenSorteio(true);
    }

    const onExportExec = () => {
        onFinishSearch(2);
    }

    return (
        <div className="p-10">
            <div>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={6}>
                        <span>
                            <h2 className="title-dashboard">Lista de clientes cadastrados</h2>
                        </span>
                    </Col>
                    <Col span={18} className="text-right">
                        <div>
                            <Row align="middle" gutter={[8, 8]} className="text-right">
                                <ModalCharts setVisible={setOpenModal} isModalVisible={openModal} />
                                <Col span={24} className="text-rigth m-t-p-20" className="p-t-27">
                                    <Button type="primary" icon={<LineChartOutlined />} size="large" onClick={() => setOpenModal(true)}>
                                        Crescimento de Cadastros
                                    </Button>
                                    {/* <div className="clientDesc">
                                        Clientes que usam o Aplicativo: <span className="clientTotal"> {formatNumber(totais.totalApp, true, '', true)}/{formatNumber(totais.total, true, '', true)}</span>
                                    </div> */}


                                    <div className="clientDesc">
                                        <div className="clientTotal">Totalizadores de clientes</div>
                                        <span>
                                            <span className="clientTotal"> {formatNumber(qtdUseApp, true, '', true)} </span> Aplicativo
                                        </span>
                                        <span>
                                            <span className="clientTotal"> {formatNumber(qtdNotUseApp, true, '', true)}</span> Sistema
                                        </span>
                                        <span>
                                            <span className="clientTotal"> {formatNumber(totalList, true, '', true)}</span> Total
                                        </span>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Form layout="vertical">
                    <Row align="middle" gutter={[8, 8]}>
                        <Col sm={20}>
                            <Form.Item>
                                <div>
                                    <label> Pesquisar por </label>
                                    <Tooltip title="Código, Nome, CPF, CEP, Cidade">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>
                                <Input.Search allowClear="true" placeholder="Código, Nome, CPF, CEP, Cidade..."
                                    value={pesquisaCliente} onChange={e => setPesqueisaCliente(e.target.value)}
                                    enterButton />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 8]}>
                        <Col sm={24}>
                            <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                                <Collapse.Panel key="1" showArrow={false} extra={
                                    <Button type="primary" onClick={() => {
                                        setOpenFilter(!openFilter);
                                        if (openFilter) {
                                            setNumPage(1);
                                            formSearch.resetFields();
                                            formSearch.submit();
                                        }
                                    }}>
                                        {openFilter ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                    </Button>
                                }>
                                    <Form form={formSearch} onFinish={() => onFinishSearch(0)}>

                                        <Row align="middle" justify="space-between" gutter={[0, 8]}>

                                            <Col span={5}>
                                                <Form.Item label="Clientes cadastrados no período " name="periodoCadastral">
                                                    <DatePicker.RangePicker format="DD/MM/YYYY" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item label="Clientes nascidos no período" name="periodoNascimento">
                                                    <DatePicker.RangePicker format="DD/MM/YYYY" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item label="Cidades" name="cidade">
                                                    <Select  placeholder="Selecionar uma cidade"
                                                        showSearch
                                                        allowClear>
                                                        {cidades.map((res, idx) => (
                                                            <Select.Option key={idx} value={res.cidade}>{`${res.cidade}`}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item  label="Local cadastrado" name="locais">
                                                    <Select mode="multiple" placeholder="Selecionar um local" allowClear>
                                                        {locais.map(res => (
                                                            <Select.Option key={res.locId} value={res.locId}>{res.locDescricao}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row align="middle" justify="start" gutter={[0, 8]}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="isRegisterApp">
                                                    <Radio.Group defaultValue={2}>
                                                        <Radio value={2}>Todos clientes</Radio>
                                                        <Radio value={0}>Clientes cadastrados via aplicativo</Radio>
                                                        <Radio value={1}>Clientes cadastrados via sistema</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>

                                            <Col span={6}>
                                                <Form.Item
                                                    valuePropName="checked"
                                                    name="bestCustomers">
                                                    <Checkbox>
                                                        Ordenar por melhores clientes
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row align="middle" justify="space-between" gutter={[0, 8]}>
                                            <Col span={6}>
                                                <Form.Item label="Período abastecido" name="dataAbastecimento">
                                                    <DatePicker.RangePicker format="DD/MM/YYYY" />
                                                </Form.Item>
                                            </Col>

                                            <Col span={6}>
                                                <Form.Item label="Valor do abastecimento >="
                                                    name="valor">
                                                    <Input placeholder="valor" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}></Col>
                                        </Row>


                                        <Row align="middle" justify="end" gutter={[0, 8]}>
                                            <Col span={6}>
                                                <ModalSorteio
                                                    loading={loadingSorteio}
                                                    setVisible={setOpenSorteio}
                                                    isModalVisible={openSorteio}
                                                    dadosForm={formSearch.getFieldsValue()}
                                                    locais={locais}
                                                    sorteado={draw} />
                                                <Button type="default" icon={<TrophyOutlined />} onClick={() => {
                                                    onPrize()
                                                }}>
                                                    Efetuar sorteio de cliente
                                                </Button>
                                            </Col>

                                            <Col span={2}>
                                                <Button type="dashed" loading={loadingExportExcel} icon={<ExportOutlined />} onClick={onExportExec}>
                                                    Export. Excel
                                                </Button>
                                            </Col>
                                            <Col span={2}>
                                                <Button type="default" icon={<ClearOutlined />} onClick={() => {
                                                    setNumPage(1);
                                                    formSearch.resetFields();
                                                    formSearch.submit();
                                                }}>
                                                    limpar Filtros
                                                </Button>
                                            </Col>
                                            <Col span={2}>
                                                <Button
                                                    type="primary" icon={<SearchOutlined />}
                                                    onClick={() => {
                                                        setNumPage(1);
                                                        formSearch.submit();
                                                    }}>
                                                    Aplicar Filtros
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>

                <div className="tabela">
                    <Table scroll={{ x: 1500 }} loading={carregando} columns={
                        [
                            {
                                title: 'Código',
                                width: 100,
                                align: 'center',
                                fixed: 'left',
                                render: ({ cliId, cliCodigo }) => (
                                    <Tag color={'processing'}>
                                        <b>#{cliCodigo ? cliCodigo : cliId}</b>
                                    </Tag>
                                ),
                            },
                            {
                                title: 'Cadastro via App',
                                fixed: 'left',
                                align: 'center',
                                width: 170,
                                render: ({ cliTermos }) => (
                                    <div>
                                        <b>{cliTermos === 1 ? <CheckOutlined style={{ color: 'green', fontSize: 16 }} /> : <CloseOutlined style={{ color: 'red', fontSize: 16 }} />}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Cliente',
                                fixed: 'left',
                                width: 170,
                                render: ({ cliNome }) => (
                                    <Popover content={cliNome}>
                                        <div>
                                            <b>{cliNome}</b>
                                        </div>
                                    </Popover>
                                ),
                            },
                            {
                                title: 'Pontos',
                                align: 'center',
                                width: 100,
                                render: ({ totalPontos }) => (
                                    <div>
                                        <span>{totalPontos}</span>
                                    </div>
                                ),
                            },
                            {
                                title: 'Data Cad. App',
                                align: 'center',
                                width: 170,
                                render: ({ cliDataCadastroApp }) => (

                                    <div>
                                        <b>{cliDataCadastroApp != null ? moment(cliDataCadastroApp).format('DD/MM/YYYY HH:mm') : ''}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Local',
                                width: 200,
                                render: ({ locCodigo, locDescricao }) => (
                                    <div>
                                        <span>{locCodigo > 0 ? `${locCodigo} - ${locDescricao}` : ""}</span>
                                    </div>
                                ),
                            },
                            {
                                title: 'Telefone',
                                align: 'center',
                                width: 125,
                                render: ({ cliCelular }) => (
                                    <div>
                                        <span>{cliCelular != null ? MaskFormat(cliCelular, "(00) 00000-0000") : "Telefone não informado."}</span>
                                    </div>
                                ),
                            },
                            {
                                title: 'Email',
                                width: 150,
                                render: ({ cliEmail }) => (
                                    <Popover content={cliEmail}>
                                        <div>
                                            <span>{cliEmail}</span>
                                        </div>
                                    </Popover>
                                ),
                            },
                            {
                                title: 'Cidade',
                                width: 125,
                                render: ({ cliCidade, cliUf }) => (
                                    <Popover content={cliCidade + ' - ' + cliUf}>
                                        <div>
                                            <span>{cliCidade + ' - ' + cliUf}</span>
                                        </div>
                                    </Popover>
                                ),
                            },
                            {
                                title: 'Endereço',
                                width: 125,
                                render: ({ cliEndereco }) => (
                                    <Popover content={cliEndereco}>
                                        <div>
                                            <span>{cliEndereco}</span>
                                        </div>
                                    </Popover>
                                ),
                            },
                            {
                                title: 'Bairro',
                                width: 125,
                                render: ({ cliBairro }) => (
                                    <div>
                                        <span>{cliBairro}</span>
                                    </div>
                                ),
                            },
                            {
                                title: 'CPF',
                                align: 'center',
                                width: 115,
                                render: ({ cliCpfCnpj }) => (
                                    <div>
                                        <span>{MaskFormat(cliCpfCnpj, "000.000.000-00")}</span>
                                    </div>
                                ),
                            },
                            {
                                title: 'Data de Aniversário',
                                width: 115,
                                render: ({ cliDataNascimento }) => (
                                    <div>
                                        <span>{moment(cliDataNascimento).format('DD/MM/YYYY HH:mm')}</span>
                                    </div>
                                ),
                            },
                            {
                                title: 'Data de Cadastro ERP',
                                width: 115,
                                render: ({ cliDataCadastro }) => (
                                    <div>
                                        <span>{moment(cliDataCadastro).format('DD/MM/YYYY HH:mm')}</span>
                                    </div>
                                ),
                            }
                        ]}
                        expandedRowRender={(record) => <SubTable record={record} />}
                        expandIcon={({ expanded, onExpand, record }) => {
                            return expanded ? (
                                <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                            ) : (
                                <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                            )
                        }}

                        pagination={{
                            current: numPage,
                            pageSize: numRegister,
                            total: totalList,
                            onChange: (pg) => setNumPage(pg),
                            onShowSizeChange: (current, page) => setNumRegister(page)
                        }}
                        dataSource={listaClientes}
                    />
                </div>
            </div>
        </div>
    );

}