import React from "react";
import ReactSummernote from "react-summernote";
import jquery from 'jquery'
import "react-summernote/dist/react-summernote.css"; // import styles
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";
// import "react-summernote/lang/summernote-pt-BR";

window.jQuery = jquery;
window.jquery = jquery;
window.$ = jquery;

export default function Index({ setMessage, message, height = 350 }) {

    const onImageUpload = (fileList) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            ReactSummernote.insertImage(reader.result);
        }
        reader.readAsDataURL(fileList[0]);
    }

    return (
        <div>
            <ReactSummernote 
                value={message}
                onInit={() => { const editArea = document.querySelector(".note-editable"); editArea.innerHTML = `<div>${message}</div>`; }}
                options={{
                    lang: 'pt-BR',
                    height: height,
                    dialogsInBody: true,
                    toolbar: [
                        ['font', ['bold', 'underline', 'italic',]],
                        ['fontsize', ['fontsize']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['insert', ['link', 'picture']],
                    ],
                    popover: {
                        image: [
                            ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
                            ['float', ['floatLeft', 'floatRight', 'floatNone']],
                            ['remove', ['removeMedia']]
                        ],
                    }
                }}
                onChange={(value) => setMessage(value)}
                onImageUpload={onImageUpload}
            />
        </div>
    );
}