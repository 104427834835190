import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "antd";

import { RedoOutlined, CheckOutlined } from "@ant-design/icons";

import api from '../../services/api';
import "../../App.less";

export default function ListRanking() {

    const [ranking, setRanking] = useState({});
    const [numPage, setNumPage] = useState(1);
    const [numRegister, setNumRegister] = useState(10);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        loadRanking();
    }, [numRegister, numPage])

    const loadRanking = () => {
        setLoad(true);
        api.get(`Abastecimento/AvgAttendant?numRegister=${numRegister}&numPage=${numPage}`).then((result) => {
            if (result.status === 200) {
                setRanking(result.data);
            }
        }).catch((err) => {
            console.log('err :>> ', err);
        }).finally(() => setLoad(false));
    }


    return (
        <Row align="middle" gutter={[8, 8]}>
            <Col span={24}>
                <div className="tabela">
                    <Table loading={load} columns={
                        [
                            {
                                title: 'Atendente',
                                render: ({ descricao }) => (
                                    <div>
                                        <b>{descricao}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Média de notas (1/5)',
                                align: 'center',
                                render: ({ media }) => (
                                    <div>
                                        <b>{media}</b>
                                    </div>
                                ),
                            },
                        ]}
                        pagination={{
                            current: numPage,
                            pageSize: numRegister,
                            total: ranking.total,
                            onChange: (pg) => setNumPage(pg),
                            onShowSizeChange: (current, page) => setNumRegister(page)
                        }}
                        dataSource={ranking.data}
                    />
                </div>
            </Col>
        </Row>
    );
}