import React, { useState, useEffect } from 'react';
import { Row, Col, List, Button } from "antd";
import { CloseOutlined, SaveFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import api from '../../services/api';
import moment from 'moment';
import formatNumber from '../../ValueObjects/formatNumber';


import "../../App.less";

export default function SubTable({ record: { cliId } }) {

    const [loading, setLoading] = useState(false);
    const [numPageHistoric, setNumPageHistoric] = useState(0);
    const [numRegisterHistoric, setNumRegisterHistoric] = useState(10);
    const [histories, setHistories] = useState([]);

    useEffect(() => {
        setLoading(true);
        api.get(`Abastecimento/GetByCliId?cliId=${cliId}&numPage=${numPageHistoric}&numRegister=${numRegisterHistoric}`).then((res) => {
            if (res.status === 200) {
                setHistories((prev) => ([...prev, ...res.data]));
            }
        }).finally(()=> setLoading(false));
    }, [numPageHistoric]);

    const onLoadMore = () => {
        setNumPageHistoric(numPageHistoric + 1);
        window.dispatchEvent(new Event("resize"));
    };
    const loadMore =
        <div
            style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
            }}
        >
            <Button onClick={onLoadMore}>Carregar Mais</Button>
        </div>

    return (
        <div>
            <div className="w-100-p">
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={2} className="text-center">Código</Col>
                    <Col span={5}>Produto</Col>
                    <Col span={8}>Posto</Col>
                    <Col span={3} className="text-center">Data Abastecimento</Col>
                    <Col span={3} className="text-center">Valor</Col>
                    <Col span={3} className="text-center">Quantidade</Col>
                </Row>
            </div>
            <List className="demo-loadmore-list"
                itemLayout="horizontal"
                loadMore={histories.length > 0 ? loadMore : null}
                dataSource={histories}
                loading={loading}
                renderItem={(item) => (
                    <List.Item>
                        <div className="w-100-p">
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={2} className="text-center">{item.abaCodigo}</Col>
                                <Col span={5}>{item.plo.pro.proDescricao}</Col>
                                <Col span={8}>{`${item.plo.loc.locCodigo} - ${item.plo.loc.locDescricao}`}</Col>
                                <Col span={3} className="text-center">{moment(item.abaDataCadastro).format('DD/MM/YYYY')}</Col>
                                <Col span={3} className="text-center">{formatNumber(item.abaValorTotal, true, 'R$')}</Col>
                                <Col span={3} className="text-center">{formatNumber(item.abaQuantidade, true, '')} L</Col>
                            </Row>
                        </div>
                    </List.Item>
                )}
            />
        </div>
    )
}